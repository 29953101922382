import React, { useState, useEffect } from 'react'
import { Card, CleanButton, TextBox, SelectBox, Loading } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { useHistory } from "react-router"
import { SITE_URL } from "../../services/Const";


let reasons = [
    { label: "PASSED AWAY", value: "PASSED AWAY" },
    { label: "KUWAIT EXIT", value: "KUWAIT EXIT" },
    { label: "OTHER", value: "OTHER" },
  ];

export default function MembershipClosing() {

    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [kicregno, setKicregno] = useState("");
    const [reason, setReason] = useState("");
    const [remark, setRemark] = useState("");
    const [cdate, setCdate] = useState("")
    const [details, setDetails] = useState([]);

    const clearData = () =>{
        setKicregno("");
        setReason("");
        setRemark("");
        setCdate("");
        setDetails([]);
      }

      const CheckUser = () =>{
        setIsLoading(true);
        const formData = {kicregno};
        axiosConfig.post("musanada-eligibility", formData, setHeader()).then((res) => {
          setDetails(res.data.data.mustablesingle);
          setIsLoading(false);

          });
      }
  
      const MembershipClose = () =>{
        if(kicregno !="" && reason !=""){
          setIsLoading(true);
          const formData = {kicregno,reason,remark,cdate};

          axiosConfig.post("membership-closing", formData, setHeader()).then((res) => {
            if (!res.data.error) {
              alert(res.data.messages);
              history.push("/membership-closed-List");
            } else {
              alert(res.data.messages);
            }
           setIsLoading(false);

          }).catch((error) => {
            alert("something went wrong! try again");
            console.log(error);
          });
        }else{
            alert("Fill KIC Register Number & Reason");
        }
      }

  return (
    <>
    <Wrapper className="container">
      <Card cardTitle="Membership Closing">
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3">
            <FormGroup>
              <Label>KIC Reg No :</Label>
              <TextBox 
              value={kicregno}
              type="text" 
              maxLength={8}
              name="kicregno" 
              onChange={(e) => setKicregno(e.target.value)}/>
            </FormGroup>
          </div>
          <div className="col-sm-1 col-md-3 col-lg-1">
          <Label></Label>
            <CleanButton isPrimary onClick={CheckUser} className="cust-pad">Check</CleanButton>
          </div>

          {details!="" ? (
            <div className="col-sm-6 col-md-6 col-lg-6 single-sec">
              <img src={`${SITE_URL}/images/registration/${details[0].photo}`} className="userimg" />
              <div className="user-details">
                <p>Name : {details[0].name}</p>
                <p>Reg No : {details[0].kic_reg_no}</p>
                <p>Zone : {details[0].zone_name}</p>
                <p>Unit : {details[0].unit_name}</p>
              </div>
          </div> )
          : ''}

        </div>
        <div className="row">
          <div className="col-sm-3 col-md-3 col-lg-3">
              <FormGroup>
                <Label>Reason:</Label>
                <SelectBox
                  data={reasons}
                  name="reason"
                  value={reason}
                  placeholder="Select Reason"
                  onChange={(e) => setReason(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3 col-md-3 col-lg-3">
                <FormGroup>
                <Label>Remark :</Label>
                <TextBox 
                value={remark}
                type="text" 
                name="remark" 
                onChange={(e) => setRemark(e.target.value)}/>
                </FormGroup>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
                <FormGroup>
                <Label>Date :</Label>
                <TextBox 
                value={cdate}
                type="date" 
                name="cdate" 
                onChange={(e) => setCdate(e.target.value)}/>
                </FormGroup>
            </div>

          <div className="col-sm-2 col-md-2 col-lg-2">
          <Label></Label>
            <CleanButton isPrimary onClick={MembershipClose} className="cust-pad">Submit</CleanButton>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <Label />
            <CleanButton isDefault className="cust-pad" onClick={clearData}>Clear</CleanButton>
          </div>
        
        </div>
      </Card>

    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}


const Wrapper = styled.div`
.single-sec{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: start;
    }
}
.userimg{
  height: 130px;
  width: 130px;
}
  .table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;