import { useState, useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import { MyThemeProvider, themeDark } from "./components/library";
import { GlobelState } from "./contextAPI/GlobelState";
import { reducer, initialState } from "./contextAPI/Reducer";
import MainWraper from "./pages/Wrapper";

const App = () => {
  const [theme, setTheme] = useState({});

  const handleThemeSet = (activeTheme) => {
    localStorage.setItem("moshco-theme", activeTheme.id);
    setTheme({
      ...activeTheme,
    });
  };

  const handleFontSet = (activeFont) => {
    localStorage.setItem("moshco-font", activeFont);
    setTheme({
      ...theme,
      fontFamily: activeFont,
    });
  };

  return (
    <GlobelState initialState={initialState} reducer={reducer}>
      <MyThemeProvider
        theme={theme}
        localStorageTheme={localStorage.getItem("moshco-theme")}
        localStorageFont={localStorage.getItem("moshco-font")}
        setTheme={handleThemeSet}
        preloaderClassName="theme-loading"
      >
        <Router>
          <MainWraper setTheme={handleThemeSet} setFont={handleFontSet} />
        </Router>
      </MyThemeProvider>
    </GlobelState>
  );
};

export default App;