import React, { useState } from 'react';
import styled from "styled-components";
import { Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import Navbar from '../../components/navbar/Navbar';


const AppRoute = ({ path, exact, component, userRole}) => {
  
    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
  
    return isAuthorized ? (
      <StyledWrapper>
      <Navbar />
      <MainDiv>
      <Route exact={exact} path={path}>
        <StyledExampleWrapper>
          {React.createElement(component)}
        </StyledExampleWrapper>
      </Route>
      </MainDiv>
      </StyledWrapper>
    ) :(
      <Redirect to="/login" />
    );
  };
  
  export default AppRoute;


  const StyledWrapper = styled.div`
   display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    flex-direction: row;
  }
`;
const MainDiv = styled.div`
  width: 100%;
  padding-left: 50px;
  padding-top: 40px;
  @media (max-width: ${(p) => p.theme.screenWidthSm}) {
    padding: 0;
  }
`;
const StyledExampleWrapper = styled.div`
  position: relative;
  overflow: auto;
  flex: 0 1 auto;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* @media (min-width: ${(p) => p.theme.screenWidthXl}) {
    flex-direction: ${(p) => (p.isDashboard ? "column" : "row")};
  } */
`;