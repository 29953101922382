import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Card, CleanButton, TextBox } from "../../components/library";
import setHeader from '../../components/Functions/setHeader';
import axiosConfig from "../../services/config/axiosConfig";

function Zone() {

    const [zones, setZones] = useState([]);
    const [zone, setZone] = useState("");
    const [zoneno, setZoneNo] = useState("");

    const [zoneid, setZoneId] = useState("");

    useEffect(() => {
        getData();
    }, []);
      
    const getData = () => {
        axiosConfig.get("zones", setHeader()).then((res) => {
            setZones(res.data.data);
        });
    };

    const clearPageState = () => {
        setZoneId("");
        setZone("");
        setZoneNo("");
        getData();
    };

    const saveZone = () => {
        const formValues = { zone, zoneno }

        if(zone!="" && zoneno !=""){   
            axiosConfig.post("insert-zone", formValues, setHeader()).then((res) => {
                if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
                
                } else {
                    alert(res.data.messages);
                }
            }).catch((error) => {
            alert("something went wrong! try again");
            });
        
        } else {
            alert("Fill Zone and Zone Number");
        }
    };

    const editZone = (zid) =>{
        axiosConfig.get(`edit-zone/${zid}`, setHeader()).then((res) => {
            setZoneId(res.data.data[0].zone_id);
            setZone(res.data.data[0].zone_name);
            setZoneNo(res.data.data[0].zone_no);
        }).catch((error) => {
            alert("something went wrong! try again");
        });
    }

    const updateZone = () =>{
        if(zoneid!=""){
            const formData = { zoneid,zone,zoneno };
            
            axiosConfig.post("update-zone", formData, setHeader()).then((res) => {
              if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
              } else {
                alert(res.data.messages);
              }
            }).catch((error) => {
              alert("something went wrong! try again");
            });
        } else {
            alert("Fill Zone");
        }
    }

    const DeleteZone = (zid) =>{
        axiosConfig.get(`delete-zone/${zid}`, setHeader()).then((res) => {

            if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
            } else {
                alert(res.data.messages);
            }
        }).catch((error) => {
            alert("something went wrong! try again");
        });
    }

  return (
    <>
    <Wrapper className='container'>
        <Card cardTitle="Zones">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-12">
                  <FormGroup>
                    <Label>Zone:</Label>
                    <TextBox 
                    type="text" 
                    name="zone"
                    placeholder="Zone"
                    value={zone}
                    onChange={(e) => setZone(e.target.value)}/>
                  </FormGroup>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-12">
                    <FormGroup>
                        <Label>Zone Number:</Label>
                        <TextBox 
                        type="number" 
                        pattern="\d*"
                        name="zoneno"
                        placeholder="Zone Number"
                        value={zoneno}
                        onChange={(e) => setZoneNo(e.target.value)}/>
                    </FormGroup>
                </div>
              </div>
             
              <div className='row'>
                  <div className="col-sm-6">

                    { zoneid =="" ? 
                        ( <CleanButton isPrimary onClick={saveZone}  className="cust-pad">Add New</CleanButton> ) : (
                        <CleanButton isPrimary onClick={updateZone}  className="cust-pad">Update</CleanButton>
                        )
                    } 
                  </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="table-responsive mx-height">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Zone</th>
                      <th>Zone Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {zones.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.zone_name}</td>
                          <td>{item.zone_no}</td>
                          <td>
                              <Link to={"/zones"} onClick={() => editZone(item.zone_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              <Link to={"/zones"} onClick={() => window.confirm("Are you sure ?") ? DeleteZone(item.zone_id) : ""} title="Delete" className="btn btn-sm btn-outline-custcolor">
                               Delete
                              </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
    </Wrapper>
    </>
  )
}

export default Zone;

const Wrapper = styled.div`

   table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .table-responsive{
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .mx-height{
    height: 440px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;