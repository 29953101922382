import React, { useState, useEffect } from "react";
import { Card, CleanButton, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";


function TimePeriod() {
    const [validation, setValidation] = useState("");
    const [period, setPeriod] = useState("");
    const [periodid, setPeriodId] = useState("");
    const [data, setData] = useState([]);

    const [musperiodid, setMusperiodId] = useState("");
    const [mstart, setmStart] = useState("");
    const [mfinish, setmFinish] = useState("");
    const [myear, setMyear] = useState("");
    const [mdata, setMdata] = useState([]);

    const [renperiodid, setRenperiodId] = useState("");
    const [rstart, setrStart] = useState("");
    const [rfinish, setrFinish] = useState("");
    const [rdata, setRdata] = useState([]);

    useEffect(() => {
      getData();
      MusPeriod();
      RenPeriod();
    }, []);
    
    const getData = () => {
      axiosConfig.get("all-periods", setHeader()).then((res) => {
        setData(res.data.data);
      });
    };
    
    const clearPageState = () => {
      setPeriod("");
      setPeriodId("");
      getData();
    };
    
    const CheckData = (data) => {
      setPeriod(data)
      if (data != "") {
        if (data.length == 9) {
          if (!isNaN(data[0] && data[1] && data[2] && data[3] && data[5] && data[6] && data[7] && data[8]) && data[4] == "-") {
                    setValidation("")
                  } else {
                    setValidation("Please enter the correct format. eg:2020-2021")
                }
            } else {
              setValidation("Please enter the correct format. eg:2020-2021")
            }
          } else {
            setValidation("")
          }
        }
        
        const formValues = { period }
        
        const savePeriod = () => {
          if(period!="" && validation==""){
            
            axiosConfig.post("insert-timeperiod", formValues, setHeader()).then((res) => {
              if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
              } else {
              alert(res.data.messages);
            }
          }).catch((error) => {
            alert("something went wrong! try again");
          });
          
        } else {
          alert("Fill Time Period or Enter the Correct Format");
        }
      };
      
      const editPeriod = (pid) => {
        axiosConfig.get(`period/${pid}`, setHeader()).then((res) => {
          setPeriodId(res.data.data[0].period_id);
          setPeriod(res.data.data[0].p_date);
        }).catch((error) => {
          alert("something went wrong! try again");
        });
      };
      
      const ActivePeriod = () => {
        axiosConfig.get("active-period", setHeader()).then((res) => {
          Cookies.remove("activeperiod");
          Cookies.set("activeperiod", res.data.data[0].period_id);
          Cookies.set("activepdate", res.data.data[0].p_date);
        }).catch((error) => {
          alert("something went wrong! try again");
        });
      };
      
      const setActive = (pid) => {
        const formData = { pid };
        axiosConfig.post("set-active-period", formData, setHeader()).then((res) => {
          if (!res.data.error) {
            alert(res.data.messages);
            ActivePeriod();
            MusPeriod();
            getData();
          } else {
            alert(res.data.messages);
          }
        }).catch((error) => {
          alert("something went wrong! try again");
        });
      };
      
      const updatePeriod = () => {
        if(period!="" && validation==""){
          const formData = { periodid,period };
          
          axiosConfig.post("update-period", formData, setHeader()).then((res) => {
            if (!res.data.error) {
              alert(res.data.messages);
              clearPageState();
            } else {
              alert(res.data.messages);
            }
          }).catch((error) => {
            alert("something went wrong! try again");
          });
        } else {
          alert("Fill Time Period or Enter the Correct Format");
        }
        
      };
      
      const clearMusPageState = () => {
        setMusperiodId("");
        setMyear("");
        setmStart("");
        setmFinish("");
        MusPeriod();
      };
      
      const MusPeriod = () => {
        axiosConfig.get("all-mus-periods", setHeader()).then((res) => {
          setMdata(res.data.data);
        });
      };

      const addMusPeriod = () => {
        const musvalus = { myear,mstart,mfinish };
        if(myear!="" && mstart!=="" && mfinish!==""){
            
          axiosConfig.post("insert-musperiod", musvalus, setHeader()).then((res) => {
            if (!res.data.error) {
              alert(res.data.messages);
              clearMusPageState();
            } else {
              alert(res.data.messages);
            }
          }).catch((error) => {
            alert("something went wrong! try again");
          });
        
        } else {
          alert("Fill all the Fields");
        }

      }

      const editMusPeriod = (pid) => {
        axiosConfig.get(`mus-period/${pid}`, setHeader()).then((res) => {
          setMusperiodId(res.data.data[0].msp_id);
          setMyear(res.data.data[0].msp_year);
          setmStart(res.data.data[0].msp_start_date);
          setmFinish(res.data.data[0].msp_finish_date);
        });
      };

      
      const updateMusPeriod = () => {
        if(myear!=="" || mstart!="" || mfinish!==""){
          let formData = { musperiodid,myear,mstart,mfinish };
          
          axiosConfig.post("update-mus-period", formData, setHeader()).then((res) => {
            if (!res.data.error) {
              alert(res.data.messages);
              clearMusPageState();
            } else {
              alert(res.data.messages);
            }
          }).catch((error) => {
            alert("something went wrong! try again");
          });
        } else {
          alert("Change any of them");
        }
        
      };

      const setMusActive = (pid) => {
        const formData = { pid };
        axiosConfig.post("set-musstatus-period", formData, setHeader()).then((res) => {
          if (!res.data.error) {
            
            if (res.data.data[0].msp_status==0) {
              alert("Activated Successfully");
              
            } else {
              alert("Deactivated Successfully");
              
            }
         }else{
          alert("Try Again")
         }
         MusPeriod();
        }).catch((error) => {
          alert("something went wrong! try again");
        });
        
      };

      const clearRenPageState = () => {
        setRenperiodId("");
        setrStart("");
        setrFinish("");
        RenPeriod();
      };

      const RenPeriod = () => {
        axiosConfig.get("all-ren-periods", setHeader()).then((res) => {
          setRdata(res.data.data);
        });
      };

      const editRenPeriod = (pid) => {
        axiosConfig.get(`ren-period/${pid}`, setHeader()).then((res) => {
          setRenperiodId(res.data.data[0].rp_id);
          setrStart(res.data.data[0].rp_start_date);
          setrFinish(res.data.data[0].rp_finish_date);
        });
      };

      const updateRenPeriod = () => {
        if(rstart!="" || rfinish!==""){
          const formData = { renperiodid,rstart,rfinish };
          
          axiosConfig.post("update-ren-period", formData, setHeader()).then((res) => {
            if (!res.data.error) {
              alert(res.data.messages);
              clearRenPageState();
            } else {
              alert(res.data.messages);
            }
          }).catch((error) => {
            alert("something went wrong! try again");
          });
        } else {
          alert("Change any of them");
        }
        
      };

      const setRenActive = (pid) => {
        const formData = { pid };
        axiosConfig.post("set-renstatus-period", formData, setHeader()).then((res) => {
          if (!res.data.error) {
            
            if (res.data.data[0].rp_status==0) {
              alert("Activated Successfully");
              
            } else {
              alert("Deactivated Successfully");
              
            }
         }else{
          alert("Try Again")
         }
         RenPeriod();
        }).catch((error) => {
          alert("something went wrong! try again");
        });
        
      };

  return (
    <Wrapper className="container">
        <Card cardTitle="Membership Period">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label>Time Period:</Label>
                    <TextBox 
                    type="text" 
                    name="foryear"
                    placeholder="YYYY-YYYY"
                    value={period}
                    onChange={(e) => CheckData(e.target.value)}/>
                    <p style={{ color: '#f00'}}>
                      {validation}
                    </p>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <Label></Label>
                  { periodid =="" ? 
                    ( <CleanButton isPrimary onClick={savePeriod}  className="cust-pad">Add New</CleanButton> ) : (
                      <CleanButton isPrimary onClick={updatePeriod}  className="cust-pad">Update</CleanButton>
                    )
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="table-responsive mx-height">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Time Period</th>
                      <th>Active Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.p_date}</td>
                          <td>{item.status==0 ? 'Inactive' : 'Active'}</td>
                          <td>
                              <Link to={"/time-period"} onClick={() => editPeriod(item.period_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              {item.status==0 ? 
                              (<Link to={"/time-period"} onClick={() => setActive(item.period_id)} title="Set As Active" className="btn btn-sm btn-outline-custcolor">
                                Activate
                              </Link>):('')
                              }
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>


        <Card cardTitle="Musanada Period">
          <div className="row">
            <div className="col-sm-5">
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label>Year:</Label>
                    <TextBox 
                    type="text" 
                    pattern="\d*"
                    placeholder="YYYY"
                    maxLength={4}
                    name="myear"
                    value={myear}
                    onChange={(e) => setMyear(e.target.value)}/>
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                  <FormGroup>
                    <Label>Campaign Start:</Label>
                    <TextBox 
                    type="date" 
                    name="mstart"
                    value={mstart}
                    onChange={(e) => setmStart(e.target.value)}/>
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label>Campaign Finish:</Label>
                    <TextBox 
                    type="date" 
                    name="mfinish"
                    value={mfinish}
                    onChange={(e) => setmFinish(e.target.value)}/>
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                <Label></Label>
                  {musperiodid =="" ?
                  ( <CleanButton isPrimary onClick={addMusPeriod}  className="cust-pad">Add New</CleanButton> ) :
                  ( <CleanButton isPrimary onClick={updateMusPeriod}  className="cust-pad">Update</CleanButton> )
                  }
                </div>
              </div>
            </div>
            <div className="col-sm-7">
              <div className="table-responsive mx-height">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Year</th>
                      <th>Start</th>
                      <th>Finish</th>
                      <th>Active Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {mdata.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.msp_year}</td>
                          <td>{item.msp_start_date}</td>
                          <td>{item.msp_finish_date}</td>
                          <td>{item.msp_status==0 ? 'Inactive' : 'Active'}</td>
                          <td>
                              <Link to={"/time-period"} onClick={() => editMusPeriod(item.msp_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              
                              <Link to={"/time-period"} onClick={() => setMusActive(item.msp_id)} className="btn btn-sm btn-outline-custcolor">
                              {item.msp_status==0 ? 'Activate' : 'Deactivate'}
                              </Link>
                              
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>

        <Card cardTitle="Membership Renewal Campaign Period">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-4">
                  <FormGroup>
                    <Label>Start Date:</Label>
                    <TextBox 
                    type="date" 
                    name="mstart"
                    value={rstart}
                    onChange={(e) => setrStart(e.target.value)}/>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <FormGroup>
                    <Label>Finish Date:</Label>
                    <TextBox 
                    type="date" 
                    name="mfinish"
                    value={rfinish}
                    onChange={(e) => setrFinish(e.target.value)}/>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                  <Label></Label>
                  <CleanButton isPrimary onClick={updateRenPeriod}  className="cust-pad">Update</CleanButton>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Start</th>
                      <th>Finish</th>
                      <th>Active Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {rdata.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.rp_start_date}</td>
                          <td>{item.rp_finish_date}</td>
                          <td>{item.rp_status==0 ? 'Inactive' : 'Active'}</td>
                          <td>
                              <Link to={"/time-period"} onClick={() => editRenPeriod(item.rp_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              
                              <Link to={"/time-period"} onClick={() => setRenActive(item.rp_id)} className="btn btn-sm btn-outline-custcolor">
                              {item.rp_status==0 ? 'Activate' : 'Deactivate'}
                              </Link>
                              
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>

    </Wrapper>
  )
}

export default TimePeriod;

const Wrapper = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .table-responsive{
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .mx-height{
    height: 250px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;