import React, { useState, useEffect } from "react";
import { Card, CleanButton, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router"
import setHeader from '../../components/Functions/setHeader';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function RenewalMore() {

  const history = useHistory();
  const [whatsapp, setWhatsapp] = useState("");

  let { ids } = useParams();

  useEffect(() => {
    userDetails();

  }, []);

  const userDetails = () => {
    axiosConfig.get(`edit-reg/${ids}`, setHeader()).then((res) => { 
      setWhatsapp(res.data.data[0].whatsapp != 'null' ? res.data.data[0].whatsapp : "");
    }).catch((error) => {
      console.log(error);
    });
  };

  console.log(whatsapp);

  const formValues = new FormData();
  formValues.append('id', ids);
  formValues.append('indiawhatsapp', whatsapp);

  const saveReg = () => {

      axiosConfig.post("update-renewal-more", formValues, setHeader()).then((res) => {
        if (!res.data.error) {
            alert("Updated successfully");
            history.push("/renew-members-list");
        } else {
          alert("Try Again");
        }
      }).catch((error) => {
        alert("something went wrong! try again");
        console.log(error);
      });
    
  };

  return (
    <Wrapper className="container">
      <Card cardTitle="Edit Whatsapp Number">
          <div className="row">
            
            <div className="col-sm-4">
              <FormGroup>
                <Label>Whatsapp: <span className="reqstart">*</span></Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  pattern="\d*"
                  international
                  defaultCountry="KW"
                  className="mobcust"
                  maxLength={15}
                  name="indiawhatsapp"
                  value={whatsapp}
                  onChange={setWhatsapp} />
                  <p style={{ color: '#f00' }}>
                    {whatsapp ? (isValidPhoneNumber(whatsapp) ? false : 'Invalid phone number') : ''}
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-2">
                <Label></Label>
              <CleanButton isPrimary onClick={saveReg}>Update</CleanButton>
            </div>
            <div className="col-sm-2">
            <Label></Label>
            <a><p
                className="cust-btn"
                isAccent
                onClick={() => history.goBack()}
              >Back</p></a>
            </div>
          </div>
      </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
.cust-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100px;
    padding: 1rem;
    border-radius: 0.75rem;
    pointer:cursor !important;
    transition-duration: 0.35s;
    background: ${(p) => p.theme.colorAccent};
    color: ${(p) => p.theme.colorAccentText};
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
}
.reqstart{
  color:red;
  font-style:bold;
}
  .mobcust{
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0;
    font-family: inherit;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      background-color: #ffffff;
      border-color: ${(p) => p.theme.colorPrimary};
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;