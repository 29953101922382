import React, { useState, useEffect } from "react";
import { Card, CleanButton, TextBox, SelectBox, TextArea, Loading } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router"
import setHeader from '../../components/Functions/setHeader';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Cookies from "js-cookie";
import { SITE_URL } from "../../services/Const";


let blood = [
  { label: "A+ve", value: "A+ve" },
  { label: "A-ve", value: "A-ve" },
  { label: "B+ve", value: "B+ve" },
  { label: "B-ve", value: "B-ve" },
  { label: "O+ve", value: "O+ve" },
  { label: "O-ve", value: "O-ve" },
  { label: "AB+ve", value: "AB+ve" },
  { label: "AB-ve", value: "AB-ve" },
];

let relation = [
  { label: "Father", value: "Father" },
  { label: "Mother", value: "Mother" },
  { label: "Wife", value: "Wife" },
  { label: "Son", value: "Son" },
  { label: "Daughter", value: "Daughter" },
  { label: "Sister", value: "Sister" },
  { label: "Grand Father", value: "Grand Father" },
  { label: "Grand Mother", value: "Grand Mother" },
];

let kwa_ind = [
  { label: "Kuwait", value: "Kuwait" },
  { label: "India", value: "India" },
];

let gender = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

let districts = [
  { label: "ALAPPUZHA", value: "ALAPPUZHA" },
  { label: "ERNAKULAM", value: "ERNAKULAM" },
  { label: "IDUKKI", value: "IDUKKI" },
  { label: "KANNUR", value: "KANNUR" },
  { label: "KASARAGOD", value: "KASARAGOD" },
  { label: "KOLLAM", value: "KOLLAM" },
  { label: "KOTTAYAM", value: "KOTTAYAM" },
  { label: "KOZHIKODE", value: "KOZHIKODE" },
  { label: "MALAPPURAM", value: "MALAPPURAM" },
  { label: "PALAKKAD", value: "PALAKKAD" },
  { label: "PATHANAMTHITTA", value: "PATHANAMTHITTA" },
  { label: "THIRUVANANTHAPURAM", value: "THIRUVANANTHAPURAM" },
  { label: "THRISSUR", value: "THRISSUR" },
  { label: "WAYANAD", value: "WAYANAD" },
  { label: "OTHER", value: "OTHER" }
];

function MemberRenewal() {

  const history = useHistory();
  const location = useLocation()

  const [noOfRows, setNoOfRows] = useState(1);
  const [whatsapp, setWhatsapp] = useState("");
  const [zone, setZone] = useState([]);
  const [unit, setUnit] = useState([]);
  const [governorate, setGovernorate] = useState([]);
  const [area, setArea] = useState([]);

  const [govrenateid, setGovrenateid] = useState("");
  const [name, setName] = useState("");
  const [nickname, setNickname] = useState("");
  const [civilid, setCivilid] = useState("");
  const [value, setValue] = useState("");

  const [photo, setPhoto] = useState("");
  const [photoview, setPhotoview] = useState("");
  const [father, setFather] = useState("");
  const [dob, setDob] = useState("");
  const [bloods, setBloods] = useState("");
  const [passport, setPassport] = useState("");
  const [education, setEducation] = useState("");
  const [email, setEmail] = useState("");
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  const [place, setPlace] = useState("");
  const [telephone, setTelphone] = useState("");
  const [housename, setHousename] = useState("");
  const [iplace, setIndianplace] = useState("");
  const [district, setDistrict] = useState("");
  const [itelephone, setIndiantel] = useState("");
  const [block, setBlock] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [kfriend, setKfriend] = useState("");
  const [kfcontact, setKfcontact] = useState("");
  const [orgnamepos, setOrgnamepos] = useState("");
  const [samasthapos, setSamasthapos] = useState("");
  const [member1, setMember1] = useState("");
  const [mem1phone, setMember1phone] = useState("");
  const [member2, setMember2] = useState("");
  const [mem2phone, setMember2phone] = useState("");
  const [careof, setCareof] = useState("");
  const [receiptno, setReceiptno] = useState("");
  const [musreceiptno, setMusReceiptno] = useState("");
  const [carea, setCarea] = useState("");
  const [cunit, setCunit] = useState("");
  const [zid, setUnitdata] = useState("");

  const [residing, setResiding] = useState("");
  const [rstatus, setRstatus] = useState("");
  const [membername, setMembername] = useState("");
  const [age, setAge] = useState("");
  const [genders, setGender] = useState("");
  const [relations, setRelation] = useState("");
  const [meducation, setMeducation] = useState("");
  const [kwaind, setKuwaitindia] = useState("");

  const [Editids, setEditids] = useState("");
  const [btnchange, setbtnchange] = useState(false);
  const [family, setFamily] = useState([]);

  const [emailError, setEmailError] = useState("");
  const [civilerror, setCivilerror] = useState("");

  const [activeyear, setActiveYear] = useState("");
  const [foryear, setForyear] = useState("");
  const [rejectreason, setRejectreason] = useState("")
  const [isLoading, setIsLoading] = useState(true);

  const isAuthorized = Cookies.get("authToken");
  const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";

  const emailValidation = (e) => {
    const tempEmail = (e.target.value);
    setEmail(tempEmail);
    const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const isValidEmail = re.test(String(tempEmail).toLowerCase());
    if (!tempEmail) {
      setEmailError('Enter a Email Address')
    } else if (!isValidEmail) {
      setEmailError('Enter a Valid Email')
    } else {
      setEmailError('');
    }
  }

  const setRadiochange = (e) => {
    const newValue = e.target.value;
    setResiding(newValue);
  }

  const setStatus = (e) => {
    const newStatus = e.target.value;
    setRstatus(newStatus);
  }

  const setPhotochange = (e) => {
    setPhoto(e.target.files[0]);
    setPhotoview(URL.createObjectURL(e.target.files[0]));
  }

  const setWhatsappno = () => setWhatsapp(value);

  let { ids } = useParams();

  useEffect(() => {
    userDetails();
    // userMusYear();
    ZoneData();
    GovernorateData();
    onLoadGovernorate();
    onloadUnit();
    familyDetails();
    ActiveMusanadaYears();
  }, []);

  const userDetails = () => {
    axiosConfig.get(`edit-reg/${ids}`, setHeader()).then((res) => {
      setName(res.data.data[0].name != 'null' ? res.data.data[0].name : "");
      setNickname(res.data.data[0].nick_name != 'null' ? res.data.data[0].nick_name : "");
      civilidCheck(res.data.data[0].civil_id_no != 'null' ? res.data.data[0].civil_id_no : "");
      setValue(res.data.data[0].mobile != 'null' ? res.data.data[0].mobile : "");
      setPhoto(res.data.data[0].photo != 'null' ? res.data.data[0].photo : "");
      setFather(res.data.data[0].father != 'null' ? res.data.data[0].father : "");
      setDob(res.data.data[0].dob != 'null' ? res.data.data[0].dob : "");
      setBloods(res.data.data[0].blood_group != 'null' ? res.data.data[0].blood_group : "");
      setPassport(res.data.data[0].passport_no != 'null' ? res.data.data[0].passport_no : "");
      setEducation(res.data.data[0].educational_qual != 'null' ? res.data.data[0].educational_qual : "");
      setEmail(res.data.data[0].email != 'null' ? res.data.data[0].email : "");
      setWhatsapp(res.data.data[0].whatsapp != 'null' ? res.data.data[0].whatsapp : "");
      setJob(res.data.data[0].job != 'null' ? res.data.data[0].job : "");
      setCompany(res.data.data[0].job_company != 'null' ? res.data.data[0].job_company : "");
      setPlace(res.data.data[0].job_place != 'null' ? res.data.data[0].job_place : "");
      setTelphone(res.data.data[0].job_phone != 'null' ? res.data.data[0].job_phone : "");
      setHousename(res.data.data[0].house_name != 'null' ? res.data.data[0].house_name : "");
      setIndianplace(res.data.data[0].house_place != 'null' ? res.data.data[0].house_place : "");
      setDistrict(res.data.data[0].district != 'null' ? res.data.data[0].district : "");
      setIndiantel(res.data.data[0].telephone != 'null' ? res.data.data[0].telephone : "");
      setGovrenateid(res.data.data[0].fk_governorate != 'null' ? res.data.data[0].fk_governorate : "");
      setCarea(res.data.data[0].fk_area_id != 'null' ? res.data.data[0].fk_area_id : "");
      setBlock(res.data.data[0].kuwait_block != 'null' ? res.data.data[0].kuwait_block : "");
      setStreet(res.data.data[0].kuwait_street != 'null' ? res.data.data[0].kuwait_street : "");
      setBuilding(res.data.data[0].kuwait_building != 'null' ? res.data.data[0].kuwait_building : "");
      setResiding(res.data.data[0].residing_kuwait_family != 'null' ? res.data.data[0].residing_kuwait_family : "");
      setKfriend(res.data.data[0].name_friend_kuwait != 'null' ? res.data.data[0].name_friend_kuwait : "");
      setKfcontact(res.data.data[0].mob_friend_kuwait != 'null' ? res.data.data[0].mob_friend_kuwait : "");
      setOrgnamepos(res.data.data[0].organization_name_position_kuwait != 'null' ? res.data.data[0].organization_name_position_kuwait : "");
      setSamasthapos(res.data.data[0].name_position_in_samastha != 'null' ? res.data.data[0].name_position_in_samastha : "");
      setMember1(res.data.data[0].witness_of_kic_member1 != 'null' ? res.data.data[0].witness_of_kic_member1 : "");
      setMember1phone(res.data.data[0].witness_of_kic_member1_phone != 'null' ? res.data.data[0].witness_of_kic_member1_phone : "");
      setMember2(res.data.data[0].witness_of_kic_member2 != 'null' ? res.data.data[0].witness_of_kic_member2 : "");
      setMember2phone(res.data.data[0].witness_of_kic_member2_phone != 'null' ? res.data.data[0].witness_of_kic_member2_phone : "");
      setCareof(res.data.data[0].care_of != 'null' ? res.data.data[0].care_of : "");
      setReceiptno(res.data.data[0].receipt_no != 'null' ? res.data.data[0].receipt_no : "");
      setMusReceiptno(res.data.data[0].mus_receipt_no != 'null' ? res.data.data[0].mus_receipt_no : "");
      setUnitdata(res.data.data[0].fk_zone_id != 'null' ? res.data.data[0].fk_zone_id : "");
      setCunit(res.data.data[0].fk_unit_id != 'null' ? res.data.data[0].fk_unit_id : "");
      setRejectreason(res.data.data[0].rejection_reason != 'null' ? res.data.data[0].rejection_reason : "");
      { isRole == 'unit' ? setRstatus(res.data.data[0].unit_status) : isRole == 'zone' ? setRstatus(res.data.data[0].zone_status) : isRole == 'central' ? setRstatus(res.data.data[0].central_status) : setRstatus("") }
      setIsLoading(false);
      
    }).catch((error) => {
      console.log(error);
    });
  };

  // const userMusYear = () => {
  //   axiosConfig.get(`edit-mus-regs/${ids}`, setHeader()).then((res) => {
  //     setForyear(res.data.data[0].renewal_year);
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // };

  const familyDetails = () => {
    axiosConfig.get(`family-details/${ids}`, setHeader()).then((res) => {
      setFamily(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  let fk_reg_id = ids;
  const addRow = () => {
    if (!membername == '' && !age == '' && !genders == '' && !relations == '' && !meducation == '' && !kwaind == '') {
      setFamily([...family, { fk_reg_id, membername, age, genders, relations, meducation, kwaind, tid: Date.now() }])
      setNoOfRows(noOfRows + 1);
      setMembername("");
      setAge("");
      setGender("");
      setRelation("");
      setMeducation("");
      setKuwaitindia("");
      // console.log(family);
    } else {
      alert("Please Fill Family Details !");
    }

  }

  const EditData = (tid) => {
    setEditids("")
    setFamily(
      family.filter(fitems => {
        if (fitems.tid == tid) {
          setMembername(fitems.membername)
          setAge(fitems.age)
          setGender(fitems.genders)
          setRelation(fitems.relations)
          setMeducation(fitems.meducation)
          setKuwaitindia(fitems.kwaind)
          setbtnchange(true)
          setEditids(tid)
        }
        return family
      })
    )
  }

  const updateData = () => {
    setFamily(
      family.filter(fitems => {
        if (fitems.tid == Editids) {
          fitems.membername = membername
          fitems.age = age
          fitems.genders = genders
          fitems.relations = relations
          fitems.meducation = meducation
          fitems.kwaind = kwaind
          setbtnchange(false)
          setEditids("")
          setMembername("");
          setAge("");
          setGender("");
          setRelation("");
          setMeducation("");
          setKuwaitindia("");
        }
        return family
      })
    )
  }

  const DeleteData = (tid) => {
    setbtnchange(false);
    setFamily(
      family.filter(fitems => {
        return fitems.tid != tid
      })
    )
  }

  const ZoneData = () => {
    axiosConfig.get("all-zones", setHeader()).then((res) => {
      setZone(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const UnitData = (id) => {
    axiosConfig.get(`all-units/${id}`, setHeader()).then((res) => {
      setUnitdata(id);
      setUnit(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const onloadUnit = () => {
    axiosConfig.get(`all-z-units/${ids}`, setHeader()).then((res) => {
      setUnit(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const GovernorateData = () => {
    axiosConfig.get("all-governorate", setHeader()).then((res) => {
      setGovernorate(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const changeGovernorate = (gid) => {
    axiosConfig.get(`all-gov-area/${gid}`, setHeader()).then((res) => {
      setGovrenateid(gid);
      setArea(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const ActiveMusanadaYears = () => {
    axiosConfig.get("latest-active-musanda-years", setHeader()).then((res) => {
      setActiveYear(res.data.data[0].label);
      setForyear(res.data.data[0].value);
    }).catch((error) => {
      console.log(error);
    });
  };

  const civilidCheck = (data) => {
    setCivilid(data)
    if (data != "") {
      if (data[0] == 2 || data[0] == 3) {
        if (data.length == 12) { //limits to 12 digit entry
          setCivilerror('')
        } else {
          setCivilerror('Civil ID Should be 12 Digits');
        }
      } else {
        setCivilerror('Civil ID should start with 2 or 3');
      }
    } else {
      setCivilerror('');
    }
  }

  const formValues = new FormData();
  formValues.append('id', ids);
  formValues.append('name', name);
  formValues.append('nickname', nickname);
  formValues.append('civilid', civilid);
  formValues.append('mobile', value);
  formValues.append('photos', photo);
  formValues.append('father', father);
  formValues.append('dob', dob);
  formValues.append('blood', bloods);
  formValues.append('passportno', passport);
  formValues.append('eduqual', education);
  formValues.append('mail', email);
  formValues.append('indiawhatsapp', whatsapp);
  formValues.append('job', job);
  formValues.append('company', company);
  formValues.append('place', place);
  formValues.append('telno', telephone);
  formValues.append('housename', housename);
  formValues.append('indiaplace', iplace);
  formValues.append('district', district);
  formValues.append('indiatelephone', itelephone);
  formValues.append('governorate', govrenateid);
  formValues.append('area', carea);
  formValues.append('block', block);
  formValues.append('street', street);
  formValues.append('building', building);
  formValues.append('residing', residing);
  formValues.append('kfriend', kfriend);
  formValues.append('kuwait_friend_no', kfcontact);
  formValues.append('organization_name_pos', orgnamepos);
  formValues.append('samastha_pos_held', samasthapos);
  formValues.append('member1', member1);
  formValues.append('mem1phone', mem1phone);
  formValues.append('member2', member2);
  formValues.append('mem2phone', mem2phone);
  formValues.append('careof', careof);
  formValues.append('receiptno', receiptno);
  formValues.append('mreceiptno', musreceiptno);
  formValues.append('foryear', foryear);
  formValues.append('zone', zid);
  formValues.append('unit', cunit);
  formValues.append('rstatus', rstatus);
  formValues.append('rejectreason', rejectreason);

  const splitData = {
    family,
    ids
  }

  const onLoadGovernorate = () => {
    axiosConfig.post('all-garea', splitData, setHeader()).then((res) => {
      setArea(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const saveReg = (e) => {
    e.preventDefault()
    setIsLoading(true);
    // console.log(formValues);
    axiosConfig.post("mem-update-regdata", formValues, setHeader()).then((res) => {
      if (!res.data.error) {
        if (res.data.messages == 'Both No Exist') {
          alert('Musanada Receipt Number & Membership Receipt Number Already Exist')
        } else if (res.data.messages == 'Reciept No Exist') {
          alert('Membership Receipt Number Already Exist')
        } else if (res.data.messages == 'MReciept No Exist') {
          alert('Musanada Receipt Number Already Exist')
        } else {
          axiosConfig.post("regsplit-update", splitData, setHeader()).then((res) => {
            if (!res.data.error) {
              alert("Updated successfully");
              history.push({ pathname: "/membership-renewed", state: location.state });

            } else {
              alert("Try Again");
            }
          }).catch((error) => {
            alert("something went wrong! try again");
            console.log(error);
          });
        }
      } else {
        alert("Try Again");
      }
    setIsLoading(false);

    }).catch((error) => {
      alert("something went wrong! try again");
      console.log(error);
    });
    setIsLoading(false);

  };

  return (
    <>
    <Wrapper className="container">
      <Card cardTitle="Membership Application Form">
        <form onSubmit={(e) => saveReg(e)}>
          <h3 className="mb">Personal Details</h3>
          <div className="row">
            <div className="col-sm-4">
              <FormGroup>
                <Label>Name / <span className="mal">പേര് (പാസ്‍പോർട്ടിലുള്ളത് പോലെ)</span>: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  name="name"
                  value={name}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  onChange={(e) => setName(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label>Civil ID No: <span className="reqstart">*</span></Label>
                <TextBox
                  placeholder="2YYMMDDSSSSS"
                  value={civilid}
                  type="number"
                  pattern="\d*"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="civilid"
                  maxLength={12}
                  onChange={(e) => civilidCheck(e.target.value)} />
                <p style={{ color: '#f00' }}>
                  {civilerror}
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Mobile: <span className="reqstart">*</span></Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  // pattern="\d*"
                  international
                  defaultCountry="KW"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  className="mobcust"
                  maxLength={15}
                  name="mobile"
                  value={value}
                  onChange={setValue} />
                <p style={{ color: '#f00' }}>
                  {value ? (isValidPhoneNumber(value) ? false : 'Invalid phone number') : ''}
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label>Photo: <span className="reqstart">*</span></Label>
                <TextBox
                  type="file"
                  name="photos"
                  onChange={setPhotochange} />
              </FormGroup>
            </div>
            <div className="col-sm-1">
              <FormGroup>
                {photoview.length != 0 ?
                  <img src={photoview} className="userimg" /> :
                  <img src={`${SITE_URL}/images/registration/${photo}`}
                    className="userimg" />
                }
              </FormGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>Nick Name / <span className="mal">വിളിപ്പേര് </span>:</Label>
                <TextBox
                  value={nickname}
                  type="text"
                  name="nickname"
                  onChange={(e) => setNickname(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Name of Father/<span className="mal">പിതാവിൻ്റെ പേര്</span>: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  name="father"
                  value={father}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  onChange={(e) => setFather(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Date of Birth: <span className="reqstart">*</span></Label>
                <TextBox
                  type="date"
                  name="dob"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  value={dob}
                  onChange={(e) => setDob(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Blood Group: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={blood}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="blood"
                  value={bloods}
                  placeholder="Select Blood Group"
                  onChange={(e) => setBloods(e.target.value)}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>Passport No: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="passportno"
                  value={passport}
                  onChange={(e) => setPassport(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Educational Qualification:</Label>
                <TextBox
                  type="text"
                  name="eduqual"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Email:</Label>
                <TextBox
                  type="email"
                  name="mail"
                  value={email}
                  onChange={emailValidation} />
                <p style={{ color: '#f00' }}>{emailError}</p>
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Whatsapp: <span className="reqstart">*</span> <input type="checkbox" onClick={setWhatsappno} /> Same as Mobile No</Label>
                <PhoneInput
                  placeholder="Enter Whatsapp number"
                  // pattern="\d*"
                  international
                  defaultCountry="KW"
                  className="mobcust"
                  name="indiawhatsapp"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  maxLength={15}
                  value={whatsapp}
                  onChange={setWhatsapp} />
                <p style={{ color: '#f00' }}>
                  {whatsapp ? isValidPhoneNumber(whatsapp) ? false : 'Invalid phone number' : ''}
                </p>
              </FormGroup>
            </div>
          </div>

          <h3 className="mb">Job Details</h3>
          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>Job: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  name="job"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  value={job}
                  onChange={(e) => setJob(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Company: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  name="company"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  value={company}
                  onChange={(e) => setCompany(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Place: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="place"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Tel No:</Label>
                <TextBox
                  type="number"
                  name="telno"
                  pattern="\d*"  //for iphone gettype number
                  value={telephone}
                  onChange={(e) => setTelphone(e.target.value)} />
              </FormGroup>
            </div>
          </div>
          <h3>Contact Details</h3>
          <h4>Address in India / <span className="mal">നാട്ടിലെ അഡ്രസ്സ്</span></h4>
          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>House Name / <span className="mal">വീട്ടുപേര്</span>: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="housename"
                  value={housename}
                  onChange={(e) => setHousename(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Place / <span className="mal">സ്ഥലം</span>: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="indiaplace"
                  value={iplace}
                  onChange={(e) => setIndianplace(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>District / <span className="mal">ജില്ല</span>: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={districts}
                  name="district"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  value={district}
                  placeholder="Select District"
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Telephone:</Label>
                <TextBox
                  type="number"
                  pattern="\d*"
                  name="indiatelephone"
                  value={itelephone}
                  onChange={(e) => setIndiantel(e.target.value)} />
              </FormGroup>
            </div>
          </div>

          <h4>Address in Kuwait / <span className="mal">കുവൈത്തിലെ അഡ്രസ്സ്</span></h4>
          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>Governorate: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={governorate}
                  value={govrenateid}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="governorate"
                  placeholder="Select Governorate"
                  onChange={(e) => changeGovernorate(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Area: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={area}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="area"
                  value={carea}
                  placeholder="Select Area"
                  onChange={(e) => setCarea(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label>Block: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  name="block"
                  value={block}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  onChange={(e) => setBlock(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label>Street: </Label>
                <TextBox
                  type="text"
                  name="street"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label>Building No:</Label>
                <TextBox
                  type="number"
                  pattern="\d*"
                  name="building"
                  value={building}
                  onChange={(e) => setBuilding(e.target.value)} />
              </FormGroup>
            </div>
          </div>

          <h3>Family Details</h3>

          <div className="row fam-row">

            <div className="col-sm-3">
              <TextBox
                type="text"
                name="membername"
                placeholder="Name"
                value={membername}
                onChange={(e) => setMembername(e.target.value)} />
            </div>
            <div className="col-sm-1">
              <TextBox
                type="number"
                name="age"
                pattern="\d*"
                value={age}
                placeholder="Age"
                onChange={(e) => setAge(e.target.value)} />
            </div>
            <div className="col-sm-2">
              <SelectBox
                data={gender}
                name="gender"
                value={genders}
                placeholder="Gender"
                onChange={(e) => setGender(e.target.value)}
                />
            </div>
            <div className="col-sm-2">
              <SelectBox
                data={relation}
                value={relations}
                name="relation"
                placeholder="Relation"
                onChange={(e) => setRelation(e.target.value)}
                />
            </div>
            <div className="col-sm-2">
              <TextBox
                type="text"
                name="education"
                value={meducation}
                placeholder="Education"
                onChange={(e) => setMeducation(e.target.value)} />
            </div>
            <div className="col-sm-2">
              <SelectBox
                data={kwa_ind}
                value={kwaind}
                name="kuwait_ind"
                placeholder="kuwait/India"
                onChange={(e) => setKuwaitindia(e.target.value)}
                />
            </div>
            {btnchange ? <button type="button" className="add-btn" title="Update" onClick={() => { updateData() }}>Update</button> : <button type="button" className="add-btn" title="Add New" onClick={() => { addRow() }}><span className="uil-plus"></span></button>}
          </div>

          {family.length != 0 &&
            <div className="row">
              <div className="col-sm-12 fam-details">
                <table>
                  <thead>
                    <th>Sl No</th>
                    <th>Name</th>
                    <th>Age</th>
                    <th>Gender</th>
                    <th>Relation</th>
                    <th>Education</th>
                    <th>Kuwait/India</th>
                    <th>Action</th>
                  </thead>
                  <tbody>
                    {family.map((item, i) => {
                      return (
                        <tr>
                          <td>{++i}</td>
                          <td>{item.membername}</td>
                          <td>{item.age}</td>
                          <td>{item.genders}</td>
                          <td>{item.relations}</td>
                          <td>{item.meducation}</td>
                          <td>{item.kwaind}</td>
                          <td>
                            <StyledIcon title="Edit" className="uil-pen" onClick={(e) => { EditData(item.tid) }} />&nbsp;
                            <StyledIcon title="Delete" className="uil-trash" onClick={(e) => { DeleteData(item.tid) }} />
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>

              </div>
            </div>
          }

          <div className="row">
            <div className="col-sm-4">
              <FormGroup>
                Are you residing in Kuwaith with your family ? :<br />
                <span className="mal">കുവൈത്തിലെ താമസം കുടുംബ സമേതമാണോ ?:</span>
              </FormGroup>
            </div>
            <div className="col-sm-2">
              <FormGroup>
                <Label className="radio-inline">
                  <input
                    type="radio"
                    name="residing"
                    value="Yes"
                    checked={residing === "Yes"}
                    onChange={setRadiochange} /> Yes&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Label>
                <Label className="radio-inline">
                  <input
                    type="radio"
                    name="residing"
                    value="No"
                    checked={residing === "No"}
                    onChange={setRadiochange} /> No
                </Label>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-6">
              <FormGroup>
                <Label>Name of Friend or Relative in Kuwait / <span className="mal">കുവൈത്തിലെ സുഹൃത്തിന്റെയോ ബന്ധുവിന്റെയോ പേര്</span>: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="kfriend"
                  value={kfriend}
                  onChange={(e) => setKfriend(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup>
                <Label>Friend Contact Number in kuwait / <span className="mal">കുവൈത്തിലെ സുഹൃത്തിന്റെയോ ബന്ധുവിന്റെയോ ഫോൺ നമ്പർ</span>: <span className="reqstart">*</span></Label>
                <PhoneInput
                  placeholder="Enter phone number"
                  // pattern="\d*"
                  international
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  defaultCountry="KW"
                  className="mobcust"
                  name="kuwait_friend_no"
                  maxLength={15}
                  value={kfcontact}
                  onChange={setKfcontact} />
                <p style={{ color: '#f00' }}>
                  {kfcontact ? (isValidPhoneNumber(kfcontact) ? false : 'Invalid phone number') : ''}
                </p>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5">
              <FormGroup>
                <Label>Name and holding position of other organization in Kuwait (if any) / <span className="mal">കുവൈത്തിൽ മറ്റേതെങ്കിലും സംഘടനയിൽ പ്രവർത്തിക്കുന്നുണ്ടെങ്കിൽ പേരും വഹിക്കുന്ന സ്ഥാനവും</span>:</Label>
                <TextArea
                  type="text"
                  name="organization_name_pos"
                  value={orgnamepos}
                  onChange={(e) => setOrgnamepos(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-7">
              <FormGroup>
                <Label>Name and position held (if you had performed in any sub division of "SAMASTHA" in the home country) / <span className="mal">നാട്ടിൽ സമസ്തയുടെ ഏതെങ്കിലും പോഷക സംഘടനയിൽ പ്രവർത്തിച്ചിട്ടുണ്ടെങ്കിൽ പേരും വഹിച്ച സ്ഥാനവും</span>:</Label>
                <TextArea
                  type="text"
                  name="samastha_pos_held"
                  value={samasthapos}
                  onChange={(e) => setSamasthapos(e.target.value)} />
              </FormGroup>
            </div>
          </div>

          <h4>Witness of two KIC Members</h4>
          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>Member 1: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="member1"
                  value={member1}
                  onChange={(e) => setMember1(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Phone: <span className="reqstart">*</span></Label>
                <PhoneInput
                  international
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  defaultCountry="KW"
                  maxLength={15}
                  // pattern="\d*"
                  name="mem1phone"
                  className="mobcust"
                  value={mem1phone}
                  onChange={setMember1phone} />
                <p style={{ color: '#f00' }}>
                  {mem1phone ? (isValidPhoneNumber(mem1phone) ? false : 'Invalid phone number') : ''}
                </p>
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Member 2: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="member2"
                  value={member2}
                  onChange={(e) => setMember2(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Phone: <span className="reqstart">*</span></Label>
                <PhoneInput
                  international
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  defaultCountry="KW"
                  maxLength={15}
                  // pattern="\d*"
                  name="mem2phone"
                  className="mobcust"
                  value={mem2phone}
                  onChange={setMember2phone} />
                <p style={{ color: '#f00' }}>
                  {mem2phone ? (isValidPhoneNumber(mem2phone) ? false : 'Invalid phone number') : ''}
                </p>
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label>C/o: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="careof"
                  value={careof}
                  onChange={(e) => setCareof(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Receipt No: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="receiptno"
                  value={receiptno}
                  onChange={(e) => setReceiptno(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Musanada Receipt No: <span className="reqstart">*</span></Label>
                <TextBox
                  type="text"
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="mreceiptno"
                  value={musreceiptno}
                  onChange={(e) => setMusReceiptno(e.target.value)} />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label>Musanada Year: </Label>
                <TextBox
                  type="text"
                  name="foryear"
                  value={activeyear}
                  disabled />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-3">
              <FormGroup>
                <Label className="phpe">Zone: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={zone}
                  value={zid}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="zone"
                  placeholder="Select Zone"
                  onChange={(e) => UnitData(e.target.value)}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup>
                <Label className="phpe">Unit: <span className="reqstart">*</span></Label>
                <SelectBox
                  data={unit}
                  value={cunit}
                  required={rstatus == 2 ? false : rstatus == 0 ? false : true}
                  name="unit"
                  placeholder="Select Unit"
                  onChange={(e) => setCunit(e.target.value)}
                />
              </FormGroup>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <FormGroup>
                <Label>Status:</Label><br />
                <Label className="radio-inline">
                  <input
                    type="radio"
                    name="rstatus"
                    value="0"
                    checked={rstatus === "0"}
                    onChange={setStatus} /> Pending&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Label>
                <Label className="radio-inline">
                  <input
                    type="radio"
                    name="rstatus"
                    value="1"
                    checked={rstatus === "1"}
                    onChange={setStatus} /> Approved&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Label>
                <Label className="radio-inline">
                  <input
                    type="radio"
                    name="rstatus"
                    value="2"
                    checked={rstatus === "2"}
                    onChange={setStatus} /> Rejected
                </Label>
              </FormGroup>
            </div>
            {rstatus=="2"? 
                <div className="col-sm-4">
                  <FormGroup>
                    <Label>Reason for Rejection: <span className="reqstart">*</span></Label>
                    <TextBox
                      type="text"
                      name="rejectreason"
                      value={rejectreason}
                      onChange={(e) => setRejectreason(e.target.value)}
                      required={rstatus == 2 ? true : false}
                       />
                  </FormGroup>
                </div> 
              : ''}
          </div>

          <div className="row">
            <div className="col-sm-2">
              {!emailError ? <CleanButton isPrimary type='submit' >Update</CleanButton> : <CleanButton isPrimary onClick={saveReg} disabled>Update</CleanButton>}
            </div>
            <div className="col-sm-2">
            <a><p
                className="cust-btn"
                isAccent
                onClick={() => history.goBack()}
              >Back</p></a>
            </div>
            {isRole=='admin' || isRole=='unit' ? 
            <div className="col-sm-4">
            <a href={`https://wa.me/${whatsapp}?text=നിങ്ങളുടെ രജിസ്ട്രേഷൻ ഫോം പൂർണ്ണമായും പൂരിപ്പിച്ചിട്ടില്ല. ശേഷിക്കുന്ന വിവരങ്ങൾ പൂരിപ്പിക്കുന്നതിന് ചുവടെയുള്ള ലിങ്കിൽ ക്ലിക്കുചെയ്യുക. https://app.kuwaitskssf.com/renewal/`} target="_blank" title="Whatsapp">
                Ask More Information <StyledIcon className="uil-whatsapp"></StyledIcon>
              </a>
            </div>
            :''}
          </div>
        </form>
      </Card>
    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}

export default MemberRenewal;

const Wrapper = styled.div`
.cust-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100px;
    padding: 1rem;
    border-radius: 0.75rem;
    pointer:cursor !important;
    transition-duration: 0.35s;
    background: ${(p) => p.theme.colorAccent};
    color: ${(p) => p.theme.colorAccentText};
    margin-block-start: 0px;
    margin-block-end: 0px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    
}
.reqstart{
  color:red;
  font-style:bold;
}
  .mobcust{
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0;
    font-family: inherit;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      background-color: #ffffff;
      border-color: ${(p) => p.theme.colorPrimary};
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
    }
  }
  .userimg{
    height: 100px;
    width: 100px;
  }
  .phpe{
    color: ${(p) => p.theme.colorPrimary};
    font-weight: bold !important;
  }
  h3{
    color: ${(p) => p.theme.colorPrimary};
  }
  .mal{
    font-size: 13px;
    /* color: #20224c; */
  }
  .mb{
    margin-bottom: 30px;
  }
  .fam-details{
    margin-bottom: 20px;
    overflow-x: auto;
  }
  .fam-row{
    margin-bottom: 15px;
    div{
      margin-bottom: 10px;
    }
  }
  .add-btn{
    border-width: 2px;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 10px 1px;
    border-radius: 3px;
    padding: 11px 22px;
    width: 100px;
    cursor: pointer;
    background-color: ${(p) => p.theme.colorPrimary};
    color: ${(p) => p.theme.colorPrimaryText};
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    span{
      color: ${(p) => p.theme.colorPrimaryText};
      font-size: 20px;
    }
  }
  .close-btn{
    border-width: 2px;
    font-weight: 600;
    font-size: 0.8571em;
    line-height: 1.35em;
    text-transform: uppercase;
    border: none;
    margin: 10px 1px;
    border-radius: 3px;
    padding: 11px 22px;
    cursor: pointer;
    background-color: ${(p) => p.theme.colorPrimary};
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -o-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    transition: all 150ms linear;
    span{
      color: ${(p) => p.theme.colorPrimaryText};
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .uil-whatsapp{
    cursor: pointer;
    font-size: 35px;
    color: #25D366;
  }
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;