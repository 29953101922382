import React, { useState, useEffect } from "react";
import { Card, CleanButton, TextBox, SelectBox, Loading } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { SITE_URL } from "../../services/Const";

export default function MusanadaChecking() {

    const [isLoading, setIsLoading] = useState(true);
    const [civilid, setCivilId] = useState("");
    const [kicregno, setKicregno] = useState("");
    const [kicidno, setKicidno] = useState("");
    const [periodid, setPeriods] = useState("");
    const [period, setPeriod] = useState([]);
    const [alldata, setAlldata] = useState([]);
    const [details, setDetails] = useState([]);

    useEffect(() => {
        Allperiod();
    }, []);

    const Allperiod = () =>{
        axiosConfig.get("all-sel-periods", setHeader()).then((res) => {
          setPeriod(res.data.data);
          setIsLoading(false);
        }).catch((error) => {
          console.log(error);
        });
    };

    const clearData = () =>{
      setPeriods("");
      setKicidno("");
      setKicregno("");
      setCivilId("");
      setAlldata([]);
      setDetails([]);
    }

    const MusanadaCheck = () =>{
        if(kicregno !="" || civilid !="" || periodid!="" && kicidno!=""){
            setIsLoading(true);
            const formData = {kicregno,civilid,periodid,kicidno};

            axiosConfig.post("musanada-eligibility", formData, setHeader()).then((res) => {
            setAlldata(res.data.data.mustable);
            setDetails(res.data.data.mustablesingle);
            setIsLoading(false);

            });
        }else{
            alert("Fill KIC Register Number or Civil ID Number or KIC ID period and KIC ID Number");
        }
    }

  return (
    <>
    <Wrapper className="container">
      <Card cardTitle="Musanada Eligibility Checking">
        <div className="row">
          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
              <Label>KIC Reg No :</Label>
              <TextBox 
              value={kicregno}
              type="text" 
              maxLength={8}
              name="kicregno" 
              onChange={(e) => setKicregno(e.target.value)}/>
            </FormGroup>
          </div>

          <p className="mt-cust">OR</p>

          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
              <Label>Civil ID No:</Label>
              <TextBox 
              placeholder="2YYMMDDSSSSS"
              value={civilid}
              type="number" 
              pattern="\d*"
              name="civilid"
              maxLength={12}
              onChange={(event) => {
                        if(event.target.value.length==13) return false;   //limits to 12 digit entry
                        setCivilId(event?.target.value);       //saving input to state
                    }
                } />
            </FormGroup>
          </div>
          <p className="mt-cust">OR</p>

          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
              <Label>KIC ID Period:</Label>
              <SelectBox
                  data={period}
                  name="period"
                  value={periodid}
                  placeholder="Select Period"
                  onChange={(e) => setPeriods(e.target.value)}
                />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
              <Label>KIC ID No:</Label>
              <TextBox 
              value={kicidno}
              type="number" 
              pattern="\d*"
              name="kicid"
              onChange={(e) => setKicidno(e.target.value)}
            />
            </FormGroup>
          </div>
          <div className="col-sm-1 col-md-3 col-lg-1">
          <Label></Label>
            <CleanButton isPrimary onClick={MusanadaCheck} className="cust-pad">Check</CleanButton>
          </div>&nbsp;&nbsp;
          <div className="col-sm-1 col-md-3 col-lg-1">
            <Label />
            <CleanButton isDefault className="cust-pad" onClick={clearData}>Clear</CleanButton>
          </div>
        
        </div>
      </Card>

      <Card cardTitle="Report">

        {details!="" ? (
        <div className="row">
          <div className="col-sm-6 single-sec">
            <img src={`${SITE_URL}/images/registration/${details[0].photo}`} className="userimg" />
            <div className="user-details">
              <p>Name : {details[0].name}</p>
              <p>Reg No : {details[0].kic_reg_no}</p>
              <p>Zone : {details[0].zone_name}</p>
              <p>Unit : {details[0].unit_name}</p>
            </div>
          </div>
        </div> )
        : ''}

        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Year</th>
                <th>Status</th>
                {/* <th>Zone</th> */}
                {/* <th>Unit</th> */}
                {/* <th>KIC Reg No</th> */}
                {/* <th>Photo</th> */}
                {/* <th>Name</th> */}
              </tr>
            </thead>
            <tbody>
              {alldata.map((item,index) => {
                return(
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.myear}</td>
                    <td>{item.user_status}</td>
                    {/* <td>{item.zone_name}</td> */}
                    {/* <td>{item.unit_name}</td> */}
                    {/* <td>{item.kic_reg_no}</td> */}
                    {/* <td>{item.photo}</td> */}
                    {/* <td>{item.name}</td> */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Card>

    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}


const Wrapper = styled.div`
.single-sec{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 575px) {
      flex-direction: column;
      align-items: start;
    }
}
.userimg{
  height: 130px;
  width: 130px;
}
.table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .mt-cust{
    margin-top: 35px;
    text-align:center;

    @media screen and (max-width: 575px) {
      display: block;
      margin: 0px auto;
    }
  }
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;