import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from "js-cookie";
import { Routes } from "./Routes";
import { useStateValue } from "../contextAPI/GlobelState";
import PublicRoute from "./Routes/PublicRoute";
import AppRoute from "./Routes/AppRoute";

import { Overlay, SettingsToggle } from "../components/library";
import Settings from "../components/settings/Settings";
import Bootstrap from "../components/styles/Bootstrap";
import setHeader from '../components/Functions/setHeader';
import axiosConfig from "../services/config/axiosConfig";


const Wrapper = ({setTheme, setFont, isDarkTheme}) => {

  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const isOverlayVisible = isSettingsOpen;
    const handleOverlayClicked = () => {
      setSettingsOpen(false);
    };

  const [{}, dispatch] = useStateValue();
  useEffect(() => {
    verifyToken();
    MusandaDateCheck();
    RenewalDateCheck();
  }, []);

  const MusandaDateCheck = () => {
    axiosConfig.get("musanada-date-check", setHeader()).then((res) => {
      // console.log(res.data);
    });
  };

  const RenewalDateCheck = () => {
    axiosConfig.get("membership-renewal-date-check", setHeader()).then((res) => {
      // console.log(res.data);
    });
  };

  const verifyToken = async () => {
    const authToken = Cookies.get("authToken");
    if (authToken) {
      await dispatch({ type: "LOGIN_AUTH", details: true });
      await dispatch({
        type: "SET_TOKEN",
        details: JSON.parse(authToken).token,
      });
    } else {
      dispatch({ type: "LOGIN_AUTH", details: false });
      dispatch({ type: "SET_TOKEN", details: "" });
    }
  };
  let admin = Routes[0].protected[0].admin;
  let unit = Routes[0].protected[0].unit;
  let zone = Routes[0].protected[0].zone;
  let central = Routes[0].protected[0].central;
  let publicAll = Routes[1].public;
  const isAuthorized = Cookies.get("authToken");
  const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
  const currentRoute =
    isRole == "admin" ? admin : isRole == "unit" ? unit : isRole == "zone" ? zone : central;
  
  return (
    <>
     <Overlay
        zIndex={5}
        onClick={handleOverlayClicked}
        isVisible={isOverlayVisible}
      />
      <Bootstrap />
      <SettingsToggle onClick={() => setSettingsOpen(true)} />
      <Settings
        isSettingsOpen={isSettingsOpen}
        setSettingsOpen={setSettingsOpen}
        setTheme={setTheme}
        setFont={setFont}
      />

      <Switch>
        {currentRoute.map((item, index) => {
          return (
            <AppRoute
              exact={item.exact}
              path={item.path}
              key={index}
              component={item.component}
            />
          );
        })}
        {publicAll.map((item, index) => {
          return (
            <PublicRoute
              exact={item.exact}
              path={item.path}
              component={item.component}
              key={index}
            />
          );
        })}

      </Switch>
    </>
  );
};
export default Wrapper;
