import React, {useState, useEffect} from "react";
import { Card, Loading, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { Link } from "react-router-dom";


export default function ClosedMembersList() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, searchDetails] = useState("");

    useEffect(() => {
        getData();
      }, []);
      
      const getData = () => {
        axiosConfig.get("closed-members-list", setHeader()).then((res) => {
          setData(res.data.data);
          setIsLoading(false);
        });
      };

  // filter table
  const filtered = !search
  ? data
  : data.filter((item) =>{
    return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
      );
    });

  return (
    <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Closed Members List">
      <div className="col-sm-3">
            <TextBox 
            type="text" 
            placeholder="Search"
            value={search}
            onChange={(e) => searchDetails(e.target.value)}
            />
        </div>
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              <th>Zone</th>
              <th>Unit</th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              <th>Whatsapp</th>
              <th>Reason</th>
              <th>Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  <td>{item.whatsapp}</td>
                  <td>{item.closing_reason}</td>
                  <td>{item.closing_date}</td>
                  <td className="action-td">
                      <Link to={"/more-details/" + item.reg_id} title="View More Details" className="btn btn-sm btn-outline-custcolor">
                        View
                      </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
    
      </Card>
    </div>
    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}


const Wrapper = styled.div`
.action-td{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;