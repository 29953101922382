import React, {useState, useEffect} from "react";
import { Card, Loading, TextBox, SelectBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { Link } from "react-router-dom";
import setHeader from '../../components/Functions/setHeader';
import Cookies from "js-cookie";

function InactiveMembers() {

    const [data, setData] = useState([]);

    const [search, searchDetails] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
    const period = Cookies.get("activeperiod");

    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);
    formValues.append('period', period);

    useEffect(() => {
      getData();
    }, []);


    const getData = () => {
      axiosConfig.post("inactive-members-list", formValues, setHeader()).then((res) => {
        setData(res.data.data);
      });
    };

    // filter table
    const filtered = !search
    ? data
    : data.filter((item) =>{
      return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
        );
      });

  return (
    <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Inactive Members List">
        <div className="row">
            <div className="col-sm-3">
                <TextBox 
                type="text" 
                placeholder="Search"
                value={search}
                onChange={(e) => searchDetails(e.target.value)}
                />
            </div>
        </div>
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              {/* <th>Form No</th> */}
              <th>Zone</th>
              <th>Unit</th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              <th>Whatsapp</th>
              <th>Period</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  {/* <td>{item.form_no}</td> */}
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  <td>{item.whatsapp}</td>
                  <td>{item.p_date}</td>
                  <td className="action-td">
                      <Link to={"/renewal-more/" + item.reg_id} title="More Details" className="btn btn-sm btn-outline-custcolor">
                        More
                      </Link> &nbsp;&nbsp;
                      <Link to={"/more-details/" + item.reg_id} title="View More Details" className="btn btn-sm btn-outline-custcolor">
                        View
                      </Link> &nbsp;&nbsp;
                      <a href={`https://wa.me/${item.whatsapp}?text=https://app.kuwaitskssf.com/renewal/`} target="_blank" title="Whatsapp">
                        <StyledIcon className="uil-whatsapp"></StyledIcon>
                      </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
    
      </Card>
    </div>
    </Wrapper>
    </>
  )
}

export default InactiveMembers

const Wrapper = styled.div`
  .action-td{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .table-responsive {
    overflow-x: scroll;
    
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 28px;
  color: #25D366;
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;