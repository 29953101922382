import React, {useState, useEffect} from "react";
import { Card, Loading, TextBox, SelectBox, CleanButton } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { Link } from "react-router-dom";
import setHeader from '../../components/Functions/setHeader';
import Cookies from "js-cookie";


const AllRegistrations = (props)=> {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, searchDetails] = useState("");
    const [zone, setZone] = useState([]);
    const [unit, setUnit] = useState([]);
    const [zid, setZoneid] = useState("");
    const [unitid, setUnitid] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
    const period = Cookies.get("activeperiod");
    const activepdate = Cookies.get("activepdate");

    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);
    formValues.append('period', period);
    formValues.append('zone', zid);
    formValues.append('unit', unitid);

    useEffect(() => {
      getData();
      ZoneData()
    }, []);

    const ZoneData = () =>{
      axiosConfig.get("all-zones", setHeader()).then((res) => {
        setZone(res.data.data);
      }).catch((error) => {
        console.log(error);
      });
    };
  
    const UnitData = (id) =>{
      axiosConfig.get(`all-units/${id}`, setHeader()).then((res) => {
        setZoneid(id);
        setUnit(res.data.data);
      }).catch((error) => {
        console.log(error);
      });
    };

    const getData = () => {
      axiosConfig.post("all-Registrations", formValues, setHeader()).then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      });
    };

    const Showfilter = () =>{
      getData()
    }

    
    // filter table
    const filtered = !search
    ? data
    : data.filter((item) =>{
      return Object.keys(item).some(key=>
         item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
        );
      });
      
      
      return (
        <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Active Members List">
        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                {/* <Label>Zone:</Label> */}
                <SelectBox
                    data={zone}
                    name="zone"
                    value={zid}
                    placeholder="Select Zone"
                    onChange={(e) => UnitData(e.target.value)}
                  />
              </FormGroup>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                {/* <Label>Unit:</Label> */}
                <SelectBox
                    data={unit}
                    name="unit"
                    value={unitid}
                    placeholder="Select Unit"
                    onChange={(e) => setUnitid(e.target.value)}
                  />
              </FormGroup>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
                <FormGroup>
                  <CleanButton isPrimary className="cust-pad" onClick={Showfilter}>Show</CleanButton>
                </FormGroup>
            </div>
            <div className="col-sm-3">
                <TextBox 
                type="text" 
                placeholder="Search"
                value={search}
                onChange={(e) => searchDetails(e.target.value)}
                />
            </div>
        </div>
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              <th>Form No</th>
              <th>Zone <span>{activepdate}</span></th>
              <th>Unit <span>{activepdate}</span></th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              <th>Whatsapp</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {filtered.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  <td>{item.form_no}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  <td>{item.whatsapp}</td>
                  <td className="action-td">
                      {/* <Link to={"/registration/edit-registration/" + item.reg_id} title="Edit" className="btn btn-sm btn-outline-custcolor">
                        Edit
                      </Link> */}
                      <Link to={"/more-details/" + item.reg_id} title="View More Details" className="btn btn-sm btn-outline-custcolor">
                        View
                      </Link> &nbsp;&nbsp;
                      {item.period_id > 3 ?
                        <Link to={"/membership-id/" + item.reg_id} title="ID Card" className="btn btn-sm btn-outline-custcolor">
                        ID
                        </Link> : ''
                      }
                  </td>
                  
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
    
      </Card>
    </div>
    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}

export default AllRegistrations;

const Wrapper = styled.div`
.cust-pad{
      padding: 11px 15px 11px 15px !important;
      /* margin-top: 8px; */
  }
.action-td{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .table-responsive {
    overflow-x: auto;
    span{
      font-size: 14px;
      display: inline-block;
      
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;