import Login from "./Auth/Login";
import Home from "./Home/Dashboard";
import AllRegistrations from "./registration/AllRegistrations";
import RegistrationEdit from "./registration/RegistrationEdit";
import RegSearch from "./registration/RegSearch";
import PendingList from "./registration/PendingList";
import MusanadaList from "./Musanada/MusanadaList";
import MusanadaRenew from "./Musanada/MusanadaRenew";
import RenewedMusanada from "./Musanada/RenewedMusanada";
import MusanadaMore from "./Musanada/MusanadaMore";
import RenewedList from "./Renewal/RenewedList";
import MemberRenewal from "./Renewal/MemberRenewal";
import RenewalApproved from "./Renewal/RenewalApproved";
import TimePeriod from "./Settings/TimePeriod";
import ExcelReport from "./registration/ExcelReport";
import Zone from "./Settings/Zone";
import Unit from "./Settings/Unit";
import Changepwd from "./Settings/Changepwd";
import RenewMembersList from "./Renewal/RenewMembersList";
import MusanadaExcel from "./Musanada/MusanadaExcel";
import MembershipId from "./registration/MembershipId";
import RejectedList from "./registration/RejectedList";
import RenewalMore from "./Renewal/RenewalMore";
import FullRegistrations from "./registration/FullRegistrations";
import MusanadaChecking from "./Musanada/MusanadaChecking";
import MembershipClosing from "./registration/MembershipClosing";
import ClosedMembersList from "./registration/ClosedMembersList";
import AdminDashboard from "./Home/AdminDashboard";
import InactiveMembers from "./registration/InactiveMembers";
import MoreDetails from "./registration/MoreDetails";
import Passwords from "./Settings/Passwords";

export const Routes = [
  {
    protected: [
      {
        admin: [
          {
            path: "/",
            exact: true,
            name: "Home",
            protected: true,
            component: AdminDashboard,
            role: "admin",
          },
          {
            path: "/search-details",
            exact: true,
            name: "Reports",
            protected: true,
            component: RegSearch,
            role: "admin",
          },
          {
            path: "/musanada-checking",
            exact: true,
            name: "Musanada Checking",
            protected: true,
            component: MusanadaChecking,
            role: "admin",
          },
          {
            path: "/membership-closing",
            exact: true,
            name: "Membership Closing",
            protected: true,
            component: MembershipClosing,
            role: "admin",
          },
          {
            path: "/membership-closed-List",
            exact: true,
            name: "Closed Members List",
            protected: true,
            component: ClosedMembersList,
            role: "admin",
          },
          {
            path: "/inactive-members",
            exact: true,
            name: "Inactive Members List",
            protected: true,
            component: InactiveMembers,
            role: "admin",
          },
          {
            path: "/full-registrations",
            exact: true,
            // name: "Full Registration",
            protected: true,
            component: FullRegistrations,
            role: "admin",
          },
          {
            path: "/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "admin",
          },
          {
            path: "/registration/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "admin",
          },
          {
            path: "/registration/edit-registration/:ids",
            exact: true,
            // name: "Reg Edit",
            protected: true,
            component: RegistrationEdit,
            role: "admin",
          },
          {
            path: "/registration/pending-report",
            exact: true,
            name: "Pending Report",
            protected: true,
            component: PendingList,
            role: "admin",
          },
          {
            path: "/rejected-list",
            exact: true,
            name: "Rejected List",
            protected: true,
            component: RejectedList,
            role: "admin",
          },
          {
            path: "/membership-id/:rid",
            exact: true,
            // name: "Membership Id",
            protected: true,
            component: MembershipId,
            role: "admin",
          },
          {
            path: "/renewal-more/:ids",
            exact: true,
            // name: "Renewal More",
            protected: true,
            component: RenewalMore,
            role: "admin",
          },
          {
            path: "/all-reports",
            exact: true,
            name: "Excel Report",
            protected: true,
            component: ExcelReport,
            role: "admin",
          },
          {
            path: "/more-details/:ids",
            exact: true,
            // name: "More",
            protected: true,
            component: MoreDetails,
            role: "admin",
          },
          {
            path: "/musanada-list",
            exact: true,
            name: "Musanada List",
            protected: true,
            component: MusanadaList,
            role: "admin",
          },
          {
            path: "/musanada-excel",
            exact: true,
            name: "Musanada Excel",
            protected: true,
            component: MusanadaExcel,
            role: "admin",
          },
          {
            path: "/renewed-list",
            exact: true,
            name: "Renewed List",
            protected: true,
            component: RenewedMusanada,
            role: "admin",
          },
          {
            path: "/musanada-renew/:ids",
            exact: true,
            protected: true,
            component: MusanadaRenew,
            role: "admin",
          },
          {
            path: "/musanada-more/:ids",
            exact: true,
            protected: true,
            component: MusanadaMore,
            role: "admin",
          },
          {
            path: "/renew-members-list",
            exact: true,
            protected: true,
            component: RenewMembersList,
            role: "admin",
          },
          {
            path: "/membership-renewed",
            exact: true,
            protected: true,
            component: RenewedList,
            role: "admin",
          },
          {
            path: "/membership-ren-update/:ids",
            exact: true,
            protected: true,
            component: MemberRenewal,
            role: "admin",
          },
          {
            path: "/membership-approved",
            exact: true,
            protected: true,
            component: RenewalApproved,
            role: "admin",
          },
          {
            path: "/time-period",
            exact: true,
            protected: true,
            component: TimePeriod,
            role: "admin",
          },
          {
            path: "/zones",
            exact: true,
            protected: true,
            component: Zone,
            role: "admin",
          },
          {
            path: "/units",
            exact: true,
            protected: true,
            component: Unit,
            role: "admin",
          },
          {
            path: "/change-password",
            exact: true,
            protected: true,
            component: Passwords,
            role: "admin",
          },
        ],
        unit: [
          {
            path: "/",
            exact: true,
            name: "Home",
            protected: true,
            component: Home,
            role: "unit",
          },
          {
            path: "/search-details",
            exact: true,
            name: "Reports",
            protected: true,
            component: RegSearch,
            role: "unit",
          },
          {
            path: "/more-details/:ids",
            exact: true,
            // name: "More",
            protected: true,
            component: MoreDetails,
            role: "unit",
          },
          {
            path: "/full-registrations",
            exact: true,
            // name: "Full Registration",
            protected: true,
            component: FullRegistrations,
            role: "unit",
          },
          {
            path: "/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "unit",
          },
          {
            path: "/registration/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "unit",
          },
          {
            path: "/rejected-list",
            exact: true,
            name: "Rejected List",
            protected: true,
            component: RejectedList,
            role: "unit",
          },
          {
            path: "/inactive-members",
            exact: true,
            name: "Inactive Members List",
            protected: true,
            component: InactiveMembers,
            role: "unit",
          },
          {
            path: "/registration/edit-registration/:ids",
            exact: true,
            // name: "Reg Edit",
            protected: true,
            component: RegistrationEdit,
            role: "unit",
          },
          {
            path: "/registration/pending-report",
            exact: true,
            name: "Pending Report",
            protected: true,
            component: PendingList,
            role: "unit",
          },
          {
            path: "/membership-id/:rid",
            exact: true,
            // name: "Membership Id",
            protected: true,
            component: MembershipId,
            role: "unit",
          },
          {
            path: "/renewal-more/:ids",
            exact: true,
            // name: "Renewal More",
            protected: true,
            component: RenewalMore,
            role: "unit",
          },
          {
            path: "/musanada-list",
            exact: true,
            name: "Musanada List",
            protected: true,
            component: MusanadaList,
            role: "unit",
          },
          {
            path: "/renewed-list",
            exact: true,
            name: "Renewed List",
            protected: true,
            component: RenewedMusanada,
            role: "unit",
          },
          {
            path: "/musanada-renew/:ids",
            exact: true,
            protected: true,
            component: MusanadaRenew,
            role: "unit",
          },
          {
            path: "/musanada-more/:ids",
            exact: true,
            protected: true,
            component: MusanadaMore,
            role: "unit",
          },
          {
            path: "/renew-members-list",
            exact: true,
            protected: true,
            component: RenewMembersList,
            role: "unit",
          },
          {
            path: "/membership-renewed",
            exact: true,
            protected: true,
            component: RenewedList,
            role: "unit",
          },
          {
            path: "/membership-ren-update/:ids",
            exact: true,
            protected: true,
            component: MemberRenewal,
            role: "unit",
          },
          {
            path: "/membership-approved",
            exact: true,
            protected: true,
            component: RenewalApproved,
            role: "unit",
          },
          {
            path: "/change-password",
            exact: true,
            protected: true,
            component: Changepwd,
            role: "unit",
          },
        ],
        zone: [
          {
            path: "/",
            exact: true,
            name: "Home",
            protected: true,
            component: AdminDashboard,
            role: "zone",
          },
          {
            path: "/search-details",
            exact: true,
            name: "Reports",
            protected: true,
            component: RegSearch,
            role: "zone",
          },
          {
            path: "/musanada-checking",
            exact: true,
            name: "Musanada Checking",
            protected: true,
            component: MusanadaChecking,
            role: "zone",
          },
          {
            path: "/full-registrations",
            exact: true,
            // name: "Full Registration",
            protected: true,
            component: FullRegistrations,
            role: "zone",
          },
          {
            path: "/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "zone",
          },
          {
            path: "/registration/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "zone",
          },
          {
            path: "/registration/edit-registration/:ids",
            exact: true,
            // name: "Reg Edit",
            protected: true,
            component: RegistrationEdit,
            role: "zone",
          },
          {
            path: "/more-details/:ids",
            exact: true,
            // name: "More",
            protected: true,
            component: MoreDetails,
            role: "zone",
          },
          {
            path: "/registration/pending-report",
            exact: true,
            name: "Pending Report",
            protected: true,
            component: PendingList,
            role: "zone",
          },
          {
            path: "/rejected-list",
            exact: true,
            name: "Rejected List",
            protected: true,
            component: RejectedList,
            role: "zone",
          },
          {
            path: "/inactive-members",
            exact: true,
            name: "Inactive Members List",
            protected: true,
            component: InactiveMembers,
            role: "zone",
          },
          {
            path: "/membership-id/:rid",
            exact: true,
            // name: "Membership Id",
            protected: true,
            component: MembershipId,
            role: "zone",
          },
          {
            path: "/renewal-more/:ids",
            exact: true,
            // name: "Renewal More",
            protected: true,
            component: RenewalMore,
            role: "zone",
          },
          {
            path: "/renew-members-list",
            exact: true,
            protected: true,
            component: RenewMembersList,
            role: "zone",
          },
          {
            path: "/musanada-list",
            exact: true,
            name: "Musanada List",
            protected: true,
            component: MusanadaList,
            role: "zone",
          },
          {
            path: "/renewed-list",
            exact: true,
            name: "Renewed List",
            protected: true,
            component: RenewedMusanada,
            role: "zone",
          },
          {
            path: "/musanada-renew/:ids",
            exact: true,
            protected: true,
            component: MusanadaRenew,
            role: "zone",
          },
          {
            path: "/musanada-more/:ids",
            exact: true,
            protected: true,
            component: MusanadaMore,
            role: "zone",
          },
          {
            path: "/membership-renewed",
            exact: true,
            protected: true,
            component: RenewedList,
            role: "zone",
          },
          {
            path: "/membership-ren-update/:ids",
            exact: true,
            protected: true,
            component: MemberRenewal,
            role: "zone",
          },
          {
            path: "/membership-approved",
            exact: true,
            protected: true,
            component: RenewalApproved,
            role: "zone",
          },
          {
            path: "/change-password",
            exact: true,
            protected: true,
            component: Changepwd,
            role: "zone",
          },
        ],
        central: [
          {
            path: "/",
            exact: true,
            name: "Home",
            protected: true,
            component: AdminDashboard,
            role: "central",
          },
          {
            path: "/search-details",
            exact: true,
            name: "Reports",
            protected: true,
            component: RegSearch,
            role: "central",
          },
          {
            path: "/musanada-checking",
            exact: true,
            name: "Musanada Checking",
            protected: true,
            component: MusanadaChecking,
            role: "central",
          },
          {
            path: "/membership-closing",
            exact: true,
            name: "Membership Closing",
            protected: true,
            component: MembershipClosing,
            role: "central",
          },
          {
            path: "/membership-closed-List",
            exact: true,
            name: "Closed Members List",
            protected: true,
            component: ClosedMembersList,
            role: "central",
          },
          {
            path: "/inactive-members",
            exact: true,
            name: "Inactive Members List",
            protected: true,
            component: InactiveMembers,
            role: "central",
          },
          {
            path: "/more-details/:ids",
            exact: true,
            // name: "More",
            protected: true,
            component: MoreDetails,
            role: "central",
          },
          {
            path: "/full-registrations",
            exact: true,
            // name: "Full Registration",
            protected: true,
            component: FullRegistrations,
            role: "central",
          },
          {
            path: "/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "central",
          },
          {
            path: "/registration/all-registration",
            exact: true,
            // name: "Reports",
            protected: true,
            component: AllRegistrations,
            role: "central",
          },
          {
            path: "/registration/edit-registration/:ids",
            exact: true,
            // name: "Reg Edit",
            protected: true,
            component: RegistrationEdit,
            role: "central",
          },
          {
            path: "/registration/pending-report",
            exact: true,
            name: "Pending Report",
            protected: true,
            component: PendingList,
            role: "central",
          },
          {
            path: "/rejected-list",
            exact: true,
            name: "Rejected List",
            protected: true,
            component: RejectedList,
            role: "central",
          },
          {
            path: "/membership-id/:rid",
            exact: true,
            // name: "Membership Id",
            protected: true,
            component: MembershipId,
            role: "central",
          },
          {
            path: "/renewal-more/:ids",
            exact: true,
            // name: "Renewal More",
            protected: true,
            component: RenewalMore,
            role: "central",
          },
          {
            path: "/renew-members-list",
            exact: true,
            protected: true,
            component: RenewMembersList,
            role: "central",
          },
          {
            path: "/musanada-list",
            exact: true,
            name: "Musanada List",
            protected: true,
            component: MusanadaList,
            role: "central",
          },
          {
            path: "/renewed-list",
            exact: true,
            name: "Renewed List",
            protected: true,
            component: RenewedMusanada,
            role: "central",
          },
          {
            path: "/musanada-renew/:ids",
            exact: true,
            protected: true,
            component: MusanadaRenew,
            role: "central",
          },
          {
            path: "/musanada-more/:ids",
            exact: true,
            protected: true,
            component: MusanadaMore,
            role: "central",
          },
          {
            path: "/membership-renewed",
            exact: true,
            protected: true,
            component: RenewedList,
            role: "central",
          },
          {
            path: "/membership-ren-update/:ids",
            exact: true,
            protected: true,
            component: MemberRenewal,
            role: "central",
          },
          {
            path: "/membership-approved",
            exact: true,
            protected: true,
            component: RenewalApproved,
            role: "central",
          },
          {
            path: "/change-password",
            exact: true,
            protected: true,
            component: Changepwd,
            role: "central",
          },
        ],
      },
    ],
  },
  {
    public: [
      {
        path: "/login",
        exact: true,
        name: "Login",
        protected: false,
        component: Login,
      },
    ],
  },
  
 
];
