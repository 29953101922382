import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Card } from "../../components/library";
import setHeader from "../../components/Functions/setHeader";
import axiosConfig from "../../services/config/axiosConfig";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

const Dashboard = () =>{


  let unitselector = "Unit";
  let zoneselector = "Zonal";
  let centralselector = "Central";

  let renewunitselector = "Unit";
  let renewzoneselector = "Zonal";
  let renewcentralselector = "Central";
  
  const [dashboardData, setDashboardData] = useState([]);
  const isAuthorized = Cookies.get("authToken");
  const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
  const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
  const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
  const period = Cookies.get("activeperiod");

  const formValues = new FormData();
  formValues.append('id', userID);
  formValues.append('role', isRole);
  formValues.append('zid', zoneID);
  formValues.append('period', period);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    axiosConfig.post("dashboard-deatils", formValues, setHeader()).then((res) => {
        setDashboardData(res.data.data);
        // console.log(res.data.data);
      }).catch((err) => {
        console.log(err);
      });
  };
 
  return (
    <Wrapper>
    <div className="container">
      <Card>
        <h4>Home</h4>
        <div className="row card-sec">
        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-users-alt icon-1"></i>
            <div className="dash-det">
              <h4>{dashboardData.totalmem[0].members}</h4>
              <SLink to={"/all-registration"}>Active Members</SLink>
            </div>
          </DashCard>
          )}
          {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-grids icon-7"></i>
            <div className="dash-det">
              <h4>{dashboardData.unitpending[0].unitpending}</h4>
              <SLink to={{pathname:"/registration/pending-report", state: unitselector}}>New Unit Pending</SLink>
            </div>
          </DashCard>
          )}
          {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-building icon-2"></i>
            <div className="dash-det">
              <h4>{dashboardData.zonepending[0].zonepending}</h4>
              <SLink to={{pathname:"/registration/pending-report", state: zoneselector}}>New Zone Pending</SLink>
            </div>
          </DashCard>
          )}

        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-grid icon-3"></i>
            <div className="dash-det">
              <h4>{dashboardData.centralpending[0].centralpending}</h4>
              <SLink to={{pathname:"/registration/pending-report", state: centralselector}}>New Central Pending</SLink>
            </div>
          </DashCard>
        )}

        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-grids icon-7"></i>
            <div className="dash-det">
              <h4>{dashboardData.renewalunitpending[0].renewalunitpending}</h4>
              <SLink to={{pathname:"/membership-renewed", state: renewunitselector}}>Renewal Unit Pending</SLink>
            </div>
          </DashCard>
        )}

        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-building icon-2"></i>
            <div className="dash-det">
              <h4>{dashboardData.renewalzonepending[0].renewalzonepending}</h4>
              <SLink to={{pathname:"/membership-renewed", state: renewzoneselector}}>Renewal Zone Pending</SLink>
            </div>
          </DashCard>
        )}

        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-grid icon-3"></i>
            <div className="dash-det">
              <h4>{dashboardData.renewalcentralpending[0].renewalcentralpending}</h4>
              <SLink to={{pathname:"/membership-renewed", state: renewcentralselector}}>Renewal Central Pending</SLink>
            </div>
          </DashCard>
        )}
        
        {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-users-alt icon-4"></i>
            <div className="dash-det">
              <h4>{dashboardData.totalreg[0].totregsiter}</h4>
              <SLink to={"/full-registrations"}>Total Registrations</SLink>
            </div>
          </DashCard>
        )}
          {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-building icon-5"></i>
            <div className="dash-det">
              <h4>{dashboardData.zone[0].zones}</h4>
              <SLink to={"/zones"}>Total Zone</SLink>
            </div>
          </DashCard>
          )}

          {dashboardData.length != 0 && (
          <DashCard>
            <i className="uil-grid icon-6"></i>
            <div className="dash-det">
              <h4>{dashboardData.unit[0].units}</h4>
              <SLink to={"/units"}>Total Unit</SLink>
            </div>
          </DashCard>
          )}

        </div>
      </Card>
    </div>
    </Wrapper>
  );
};

export default Dashboard;

const Wrapper = styled.div`
  .card-sec {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
`;

const DashCard = styled.div`
  box-shadow: 0px 0px 14px -5px ${(p) => p.theme.colorPrimary};
  color: ${(p) => p.theme.colorLabel};
  height: 150px;
  border-radius: 10px;
  width: 240px;
  margin: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .dash-det {
    text-align: right;
    h4 {
      margin: 5px 0;
    }
  }
  i {
    font-size: 19px;
    border: 5px solid #f3eeee;
    border-radius: 50px;
    padding: 10px;
    height: 50px;
    width: 50px;
  }
  .icon-1 {
    background-color: #eef39e8f;
    color: #97811a;
  }
  .icon-2 {
    background-color: #8ea1f0b0;
    color: #3a57cd;
  }
  .icon-3 {
    background-color: #d7333359;
    color: #bf1818;
  }
  .icon-4 {
    background-color: #fce4ec;
    color: #e91e63d9;
    box-shadow: -1px 1px 12px -1px #e391add9;
  }
  .icon-5 {
    background-color: #ffccbc;
    color: #ff5722;
    box-shadow: -1px 1px 12px -1px #e38061db;
  }
  .icon-6 {
    background-color: #cfd8dc;
    color: #607d8b;
    box-shadow: -1px 1px 12px -1px #6988978c;
  }
  .icon-7 {
      background-color: #d7ccc8;
      color: #795548;
      box-shadow: -1px 1px 12px -1px #a98579b3;
    }
  @media (max-width: ${(p) => p.theme.screenWidthSm}) {
    width: 150px;
    height: 150px;
    .dash-det {
      Link {
        font-size: 13px;
      }
      h4 {
        font-size: 15px;
      }
    }
  }
  @media (min-width: 320px) and (max-width: 371px) {
    width: 160px;
    height: 145px;
    .dash-det {
      p {
        font-size: 13px;
      }
      h4 {
        font-size: 15px;
      }
    }
  }
`;
const SLink = styled(Link)`
    margin: 0;
    color: ${(p) => p.theme.colorLabel};
`;
const Margin = styled.div`
  margin: 10px;
`;
const Table = styled.table`
  display: block;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 1.5rem;
  background-color: transparent;
  thead,
  tr,
  th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
  }
`;
const Thead = styled.thead`
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
`;
const Tr = styled.tr``;
const Tbody = styled.tbody``;
const Th = styled.th`
  padding: 0.75rem;
`;
const Td = styled.td`
  padding: 0.75rem;
`;
