import React, { useEffect , useState } from 'react';
import styled from 'styled-components';
import { Card,CleanButton } from "../../components/library";
import { useParams } from "react-router-dom";
import axiosConfig from "../../services/config/axiosConfig";
import { SITE_URL } from "../../services/Const";


function MembershipId() {

    const [details, setDetails] = useState([]);
    const [idyear, setIDdetails] = useState([]);

    let { rid } = useParams();

    useEffect(() => {
        userDetails();
    }, []);
  
    const userDetails = () =>{
        axiosConfig.get(`idcard-details/${rid}`).then((res) => {
            setDetails(...res.data.data.idcard);
            setIDdetails(...res.data.data.idyear);
        }).catch((error) => {
            console.log(error);
        });
    };
    
  return (
    <>

<Card>
<Wrapper className="card" id="printablediv">
    <div className="img-sec">
      <img src={`${SITE_URL}/images/registration/${details.photo}`}/>
    </div>
    
    
      <div className="stats">
          <h3>MEMBERSHIP ID CARD</h3>
          <h4>NAME: {details.name}</h4>
          <h4>KIC ID: {details.kic_id_no}</h4>
          <h4>REG.NO: {details.kic_reg_no}</h4>
      </div>

  </Wrapper>

  <Wrapper2 className="card" id="printablediv">
    <p className='year'>Valid Till: 31<sup>st</sup> December {idyear.p_date ? idyear.p_date.substring(5, 9) : ''}</p>
  </Wrapper2>

    <div className='row prntbtn'>
      <div className='col-sm-2 col-3'></div>

      <div className='col-sm-4 col-6'>
        <a href={"https://app.kuwaitskssf.com/new-registration/membership-id/" + details.reg_id}  className="btn btn-sm btn-outline-custcolor" target="_blank"> Print </a>&nbsp;&nbsp;&nbsp;
        <a href={`https://wa.me/${details.whatsapp}?text=https://app.kuwaitskssf.com/new-registration/membership-id/${details.reg_id}/`} target="_blank" title="Whatsapp">
            Share <StyledIcon className="uil-whatsapp col-sm-1"></StyledIcon>
        </a>
      </div>
      <div className='col-sm-3'></div>

    </div>
    </Card>

   
    </>
  )
}

export default MembershipId;

const Wrapper = styled.div`
     padding: 2.5rem 2rem;
     border-radius: 10px;
     -webkit-print-color-adjust: exact;
     background: url(${SITE_URL}/images/bg.jpeg);
     background-repeat: no-repeat;
     max-width: 500px;
     box-shadow: 0 0 30px rgba(0, 0, 0, .15);
     margin: 1rem;
     height: 350px;
     position: relative;
     /* transform-style: preserve-3d; */
     overflow: hidden;
    display: flex;
    &:before{
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: 1px solid #FFF;
        border-radius: 10px;
        top: -.7rem;
        left: -.7rem;
    }
    /* &:after{
        border-radius: 50%;
        content: '';
        position: absolute;
        z-index: -1;
        height: 15rem;
        width: 15rem;
        background-color: #4172f5aa;
        top: -8rem;
        right: -8rem;
        box-shadow: 2rem 6rem 0 -3rem #FFF
    } */
    .img-sec{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        img{
            width: 7rem;
            min-width: 80px;
            margin-bottom: 31px;
            height: 130px;
            box-shadow: 0 0 0 5px #FFF;
        }
    }
.stats  {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-left: 25px;
    margin-bottom: 20px;

    h3{
        font-size: 20px;
        color: #05295f;
    }
    h4 {
        font-size: 15px;
        margin: 8px 0px;
    }
    
}

@media screen and (max-width: 450px) {
  .card {
    display: block;
  }
}

`;



const Wrapper2 = styled.div`
     padding: 2.5rem 2rem;
     border-radius: 10px;
     -webkit-print-color-adjust: exact;
     background: url(${SITE_URL}/images/bg2.jpeg);
     background-repeat: no-repeat;
     max-width: 500px;
     box-shadow: 0 0 30px rgba(0, 0, 0, .15);
     margin: 1rem;
     height: 350px;
     position: relative;
     /* transform-style: preserve-3d; */
     overflow: hidden;
    display: flex;
    &:before{
        content: '';
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border: 1px solid #FFF;
        border-radius: 10px;
        top: -.7rem;
        left: -.7rem;
    }
    .year{
        position: absolute;
        bottom: 4px;
        right: 15px;
        color: #1c1462;
        font-weight: 900;
        font-size: 15px;
    }

@media screen and (max-width: 450px) {
  .card {
    display: block;
  }
}

`;

const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 35px;
  color: #25D366;
`;