import axios from "axios";
import { API_URL } from "../Const";
// import Cookies from "js-cookie";
// const token = Cookies.get("authToken");
const instance = axios.create({
  baseURL: API_URL,
  // timeout: 10000,
  // headers: { 'api-key': 'eyJz-CI6Ikp-4pWY-lhdCI6' }
});
//   instance.defaults.headers.common['Authorization'] = 'Bearer ' + token;
// instance.defaults.headers.common['Authorization'] = token ? `Bearer ${token}` : '';
// instance.defaults.headers.common['Authorization'] = `Bearer ${token}`
// token &&
//   (instance.defaults.headers.common["Authorization"] = JSON.parse(token).token);
export default instance;

// import axiosConfig from '../../axiosConfig';

// axiosConfig.get('/posts' )
// .then(response => {
//        this.setState({data: response});
//     });
// })
// .catch(error => {
//     this.setState({error: true});
// })
