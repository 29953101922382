import React, { useState, useEffect } from "react";
import { Card, CleanButton, TextBox, SelectBox, TextArea } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router"
import setHeader from '../../components/Functions/setHeader';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

  let status = [
    { label: "PAID", value: "PAID"},
    { label: "VACATION", value: "VACATION"},
    { label: "KUWAIT EXIT", value: "KUWAIT EXIT"},
    { label: "NOT REACHABLE", value: "NOT REACHABLE"},
    { label: "AWAITING", value: "AWAITING"},
    { label: "MESSAGE RECEIVED AND NO RESPONSE", value: "MESSAGE RECEIVED AND NO RESPONSE"},
    { label: "NOT INTERESTED", value: "NOT INTERESTED"},
    { label: "PASSED AWAY", value: "PASSED AWAY"}
  ];

export default function MusanadaRenew() {

    const history = useHistory();
 
    const [statuss, setStatus] = useState("");
    const [remark, setRemark] = useState("");
    const [renewaldate, setRenewaldate] = useState("");
    const [foryear, setForyear] = useState("");
    const [mobile, setMobile] = useState("");
    const [location, setLocation] = useState("");
    const [receipt, setReceipt] = useState("");
    const [activeyear, setActiveYear] = useState([]);

    useEffect(() => {
      ActiveMusanadaYears();
    }, []);


    let { ids } = useParams();

    const MoreDetails = () => {
      history.push("/musanada-more/"+ ids);
    }

    const ActiveMusanadaYears = () =>{
      axiosConfig.get("active-musanda-years", setHeader()).then((res) => {
        setActiveYear(res.data.data);
      }).catch((error) => {
        console.log(error);
      });
    };

    const saveRenewal = () => {
      if(
        statuss !="" && foryear!=""
        ){
          const formData = {
            ids,
            statuss,
            remark,
            renewaldate,
            foryear,
            mobile,
            location,
            receipt
          };

          const formValues = {ids,mobile};
  
          axiosConfig.post("update-mob-data", formValues, setHeader()).then((res) => {
          if (!res.data.error) {
              axiosConfig.post("musanada-renewal", formData, setHeader()).then((res) => {
                if (!res.data.error) {
                  alert("Renewed Successfully");
                  history.push("/musanada-list");
                  
                } else {
                  alert("Try Again");
                }
              }).catch((error) => {
                alert("something went wrong! try again");
                console.log(error);
              });
          } else {
            alert("Already have a Mobile Number");
          }
        }).catch((error) => {
          alert("something went wrong! try again");
          console.log(error);
        });
      } else {
        alert("Fill Payment Year & Current Status");
      }
    };


  return (
    <Wrapper className="container">
        <Card cardTitle="Musanada Renewal">

        <div className="row">
        <div className="col-sm-2">
            <FormGroup>
              <Label>Payment Year:</Label>
              <SelectBox
                  data={activeyear}
                  value={foryear}
                  name="foryear"
                  placeholder="Select Year"
                  onChange={(e) => setForyear(e.target.value)}
                />
            </FormGroup>
          </div>
           <div className="col-sm-3">
            <FormGroup>
              <Label>Current Status:</Label>
              <SelectBox
               data={status}
               name="blood"
               value={statuss}
               placeholder="Select Status"
               onChange={(e) => setStatus(e.target.value)}
               />
            </FormGroup>
           </div>
           <div className="col-sm-3">
            <FormGroup>
              <Label>Current Number:</Label>
              <PhoneInput
              placeholder="Enter phone number"
              pattern="\d*"
              international
              defaultCountry="KW"
              className="mobcust"
              maxLength={15}
              name="mobile"
              value={mobile}
              onChange={setMobile}/>
              <p style={{ color: '#f00'}}>
                {mobile ? (isValidPhoneNumber(mobile) ? false : 'Invalid phone number') : ''}
              </p>
            </FormGroup>
          </div>
          <div className="col-sm-2">
            <FormGroup>
              <Label>Paid Date:</Label>
              <TextBox 
              type="date" 
              name="renewaldate"
              value={renewaldate}
              onChange={(e) => setRenewaldate(e.target.value)}/>
            </FormGroup>
          </div>
          <div className="col-sm-2">
            <FormGroup>
              <Label>Receipt Number:</Label>
              <TextBox 
              type="number" 
              pattern="\d*"
              maxLength={5}
              name="receipt"
              value={receipt}
              onChange={(event) => {
                      if(event.target.value.length==6) return false;   //limits to 5 digit entry
                      setReceipt(event?.target.value);       //saving input to state
                  }
              }
             />
            </FormGroup>
          </div>
        </div>

        <div className="row">
        <div className="col-sm-3">
            <FormGroup>
              <Label>Current Location:</Label>
              <TextArea 
              type="text" 
              name="location" 
              value={location}
              onChange={(e) => setLocation(e.target.value)}/>
            </FormGroup>
          </div>
          <div className="col-sm-3">
            <FormGroup>
              <Label>Remark:</Label>
              <TextArea 
              type="text" 
              name="remark" 
              value={remark}
              onChange={(e) => setRemark(e.target.value)}/>
            </FormGroup>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-2">
             <CleanButton isPrimary onClick={saveRenewal} className="cust-pad">Renew</CleanButton>
          </div>
          <div className="col-sm-2">
             <CleanButton isAccent onClick={MoreDetails} className="cust-pad">More Details</CleanButton>
          </div>

        </div>
        </Card>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .mobcust{
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin: 0;
    font-family: inherit;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      background-color: #ffffff;
      border-color: ${(p) => p.theme.colorPrimary};
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;