import React, { useState, useEffect } from 'react';
import { Card, CleanButton, SelectBox, Loading, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useLocation } from "react-router"

function PendingList() {

  useEffect(() => {
    ZoneData()

    if (location.state == 'Unit') {
      showAll()
    } else if (location.state == 'Zonal') {
      zonalPending()
    } else if (location.state == 'Central') {
      centralPending()
    }
  }, [])
  const [pending, setPending] = useState([]);
  const [selector, setselector] = useState("");
  const [selectorshow, setSelectorshow] = useState(false);
  const [zone, setZone] = useState([]);
  const [unit, setUnit] = useState([]);
  const [zid, setZoneid] = useState("");
  const [unitid, setUnitid] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [search, searchDetails] = useState("");

  const location = useLocation()
  const isAuthorized = Cookies.get("authToken");
  const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
  const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
  const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
  const period = Cookies.get("activeperiod");

  const title = selector + " " +"Pending List"

  const formValues = new FormData();
  formValues.append('id', userID);
  formValues.append('role', isRole);
  formValues.append('zid', zoneID);
  formValues.append('period', period);
  formValues.append('zone', zid);
  formValues.append('unit', unitid);

  const showAll = () => {
    setIsLoading(true);
    axiosConfig.post("unit-pending", formValues, setHeader()).then((res) => {
      setSelectorshow(true)
      setPending(res.data.data);
      setIsLoading(false);
      setselector('Unit')
    });
  };

  const ZoneData = () =>{
    axiosConfig.get("all-zones", setHeader()).then((res) => {
      setZone(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const UnitData = (id) =>{
    axiosConfig.get(`all-units/${id}`, setHeader()).then((res) => {
      setZoneid(id);
      setUnit(res.data.data);
    }).catch((error) => {
      console.log(error);
    });
  };

  const zonalPending = () => {
    setIsLoading(true);
    axiosConfig.post("zonal-pending", formValues, setHeader()).then((res) => {
      setSelectorshow(true)
      setPending(res.data.data);
      setIsLoading(false);
      setselector('Zonal')
    });
  };

  const centralPending = () => {
    setIsLoading(true);
    axiosConfig.post("central-pending", formValues, setHeader()).then((res) => {
      setSelectorshow(true)
      setPending(res.data.data);
      setIsLoading(false);
      setselector('Central')

    });
  };

  const DeleteData = (id) => {
      axiosConfig.get(`delete-reg-data/${id}`, setHeader()).then((res) => {
          if (!res.data.error) {
            alert(res.data.messages)
            showAll()
          } else {
            alert('Try again')
          }
      })
        .catch((error) => {
          console.log(error);
      });
  }

   // filter table
   const filtered = !search
   ? pending
   : pending.filter((item) =>{
     return Object.keys(item).some(key=>
         item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
       );
     });
 

  return (
    <>
      <Wrapper>
        <div className="container">
          <Card cardTitle={title}>
            <div className="row">
            <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                <Label>Zone:</Label>
                <SelectBox
                    data={zone}
                    name="zone"
                    value={zid}
                    placeholder="Select Zone"
                    onChange={(e) => UnitData(e.target.value)}
                  />
              </FormGroup>
            </div>
            <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                <Label>Unit:</Label>
                <SelectBox
                    data={unit}
                    name="unit"
                    value={unitid}
                    placeholder="Select Unit"
                    onChange={(e) => setUnitid(e.target.value)}
                  />
              </FormGroup>
            </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <FormGroup>
                  <Label />
                 <CleanButton isAccent className="cust-pad" onClick={showAll}>Unit Pending</CleanButton>
                </FormGroup>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <FormGroup>
                  <Label />
                 <CleanButton isPrimary className="cust-pad" onClick={zonalPending}>Zonal Pending</CleanButton>
                </FormGroup>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
                <FormGroup>
                  <Label />
                  <CleanButton isDefault className="cust-pad" onClick={centralPending}>Central Pending</CleanButton>
                </FormGroup>
              </div>
              <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                <Label />
                <TextBox 
                type="text" 
                className="cust-mt"
                placeholder="Search"
                value={search}
                onChange={(e) => searchDetails(e.target.value)}
                />
                </FormGroup>
              </div>
            </div>

            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    {/* <th>KIC Id</th> */}
                    <th>KIC Reg No</th>
                    <th>Form No</th>
                    <th>Zone</th>
                    <th>Unit</th>
                    <th>Name</th>
                    <th>Civil Id</th>
                    <th>Mobile</th>
                    <th>Whatsapp</th>
                    <th>Unit Status</th>
                    <th>Zone Status</th>
                    <th>Central Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                {filtered.length > 0 ? filtered.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {/* <td>{item.kic_id_no}</td> */}
                        <td>{item.kic_reg_no}</td>
                        <td>{item.form_no}</td>
                        <td>{item.zone_name}</td>
                        <td>{item.unit_name}</td>
                        <td>{item.name}</td>
                        <td>{item.civil_id_no}</td>
                        <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                        <td>{item.whatsapp}</td>
                        <td>{item.unit_status == 0 ? 'Pending' : item.unit_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>{item.zone_status == 0 ? 'Pending' : item.zone_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>{item.central_status == 0 ? 'Pending' : item.central_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>
                        {isRole=='admin' || isRole=='unit' && selector=='Unit' ? 
                          <Link to={{ pathname: "/registration/edit-registration/" + item.reg_id, state: selector }} title="Edit" className="btn btn-sm btn-outline-custcolor">
                            Edit/Approval
                          </Link> 
                          : isRole=='admin' || isRole=='zone' && selector=='Zonal' ? 
                          <Link to={{ pathname: "/registration/edit-registration/" + item.reg_id, state: selector }} title="Edit" className="btn btn-sm btn-outline-custcolor">
                            Edit/Approval
                          </Link>
                          : isRole=='admin' || isRole=='central' && selector=='Central' ?
                          <Link to={{ pathname: "/registration/edit-registration/" + item.reg_id, state: selector }} title="Edit" className="btn btn-sm btn-outline-custcolor">
                            Edit/Approval
                          </Link> : 'Not Allowed'}&nbsp;
                          
                          {isRole=='admin' ? <Link to={"/registration/pending-report"} title="Delete" onClick={() => window.confirm("Are you sure to Delete ?") ? DeleteData(item.reg_id) : ""} className="btn btn-sm btn-outline-custcolor">Delete</Link> : ""}
                        </td>
                      </tr>
                    );
                  }) : selectorshow ?  <tr><td colSpan={13} style={{ textAlign: 'center' }}><p><b>No Data Found</b></p></td></tr> : ""}
                </tbody>
              </table>
            </div>
          </Card>


        </div>
      </Wrapper>
      {isLoading && <Loading />}
    </>
  )
}

export default PendingList;

const Wrapper = styled.div`
.cust-mt{
  margin-top:9px;
}
.table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  @media screen and (max-width: 480px) {
    .cust-pad{
        margin-top: unset;
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;