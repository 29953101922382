export const initialState = {
    salesProducts: [],
    user: [],
    salesPriceType: "",
    databaseAuth: [],
    isSidebar: false,
    isLogin: false,
    role: "",
    token: "",
    baseUrl: "http://localhost:8080/kic-api/api/",
  };
  export const getSalesProductsCount = (salesProducts) => salesProducts.length();
  
  export const reducer = (state, action) => {
    switch (action.type) {
      case "SIDEBAR":
        return { ...state, isSidebar: action.details };
      case "LOGIN_AUTH":
        return { ...state, isLogin: action.details };
      case "SET_ROLE":
        return { ...state, role: action.details };
      case "SET_TOKEN":
        return { ...state, token: action.details };
      case "ADD_SALES_PRODUCT":
        return {
          ...state,
          salesProducts: [...state.salesProducts, action.item],
        };
      case "REMOVE_SALES_PRODUCT":
        let newSalesProducts = [...state.salesProducts];
        const index = state.salesProducts.findIndex(
          (salesItem) => salesItem.psp_ir_id === action.id
        );
        if (index >= 0) {
          newSalesProducts.splice(index, 1);
        }
        return { ...state, salesProducts: newSalesProducts };
      case "REMOVE_ALL_SALES_PRODUCT":
        return {
          ...state,
          salesProducts: [],
        };
      case "UPDATE_SALES_PRODUCT":
        let updateSalesProducts = [...state.salesProducts];
        const uItems = state.salesProducts.findIndex(
          (salesItem) => salesItem.psp_ir_id === action.item.psp_ir_id
        );
        if (uItems >= 0) {
          updateSalesProducts.splice(uItems, 1);
        }
        updateSalesProducts.push(action.item);
        return {
          ...state,
          salesProducts: updateSalesProducts,
        };
      case "CHANGE_SALES_PRICE":
        return { ...state, salesPriceType: action.sales_type };
      case "CHANGE_SALES_ORDER_TYPE":
        return { ...state, salesOrderType: action.sales_order_type };
      case "ADD_DB_TOKEN":
        return { ...state, databaseAuth: action.details };
      case "ADD_USER_DETAILS":
        return { ...state, user: action.details };
      case "IS_IMPORT":
        return { ...state, isImport: action.details };
      default:
        return state;
    }
  };
  