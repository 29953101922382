import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Card, CleanButton, TextBox, SelectBox } from "../../components/library";
import setHeader from '../../components/Functions/setHeader';
import axiosConfig from "../../services/config/axiosConfig";

function Passwords() {

    const [passwords, setpasswords] = useState([]);
    const [lid, setlid] = useState("");
    const [pwd, setPwd] = useState("");
    const [search, searchDetails] = useState("");

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        axiosConfig.get("passwords", setHeader()).then((res) => {
            setpasswords(res.data.data);
        });
    };

    // filter table
    const filtered = !search
    ? passwords
    : passwords.filter((item) =>{
      return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
        );
      });
      

    const clearPageState = () => {
        setlid("");
        setPwd("");
        getData();
    };

    const editPasswd = (lid) =>{
        axiosConfig.get(`edit-password/${lid}`, setHeader()).then((res) => {
            setlid(res.data.data[0].l_id);
            setPwd(res.data.data[0].password);
        }).catch((error) => {
            alert("something went wrong! try again");
        });
    }

    const updatePwd = () =>{
        if(lid!=""){
            const formData = { lid, pwd };
            
            axiosConfig.post("update-password", formData, setHeader()).then((res) => {
              if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
              } else {
                alert(res.data.messages);
              }
            }).catch((error) => {
              alert("something went wrong! try again");
            });
        } else {
            alert("Fill Unit");
        }
    }
    

  return (
    <>
      <Wrapper className="container">
      <Card cardTitle="Passwords">
          <div className="row">
          { lid !=="" ? 
          (
            <div className="col-sm-4">
              <div className="row">
                <div className="col-sm-12">
                    <FormGroup>
                        <Label>Password:</Label>
                        <TextBox 
                        type="text" 
                        name="pwd"
                        placeholder="Password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}/>
                    </FormGroup>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-6">
                    <CleanButton isPrimary onClick={updatePwd}  className="cust-pad">Update</CleanButton>
                </div>
              </div>
             
            </div>
            ):''
            }
            <div className="col-sm-8">
              <div className="col-sm-4">
                <TextBox 
                type="text" 
                placeholder="Search"
                value={search}
                onChange={(e) => searchDetails(e.target.value)}
                />
              </div>
              <div className="table-responsive mx-height">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Zone</th>
                      <th>Unit</th>
                      <th>Role</th>
                      <th>Username</th>
                      <th>Password</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.zone_name}</td>
                          <td>{item.unit_name}</td>
                          <td>{item.role}</td>
                          <td>{item.username}</td>
                          <td>{item.password}</td>
                          <td>
                              <Link to={"/change-password"} onClick={() => editPasswd(item.l_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      </Wrapper>
    </>
  )
}

export default Passwords

const Wrapper = styled.div`

   table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .table-responsive{
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .mx-height{
    height: 440px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;