import React, { useState, useEffect } from 'react';
import { Card, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

export default function RejectedList() {

    useEffect(() => {
        showAll();
      }, [])

    const [rejected, setRejected] = useState([]);
    const [search, searchDetails] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
    const period = Cookies.get("activeperiod");
  
  
    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);
    formValues.append('period', period);
  
    const showAll = () => {
      axiosConfig.post("rejected-list", formValues, setHeader()).then((res) => {
        setRejected(res.data.data);
      });
    };
    
  // filter table
  const filtered = !search
  ? rejected
  : rejected.filter((item) =>{
    return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
      );
    });

  return (
    <>
      <Wrapper>
        <div className="container">
          <Card cardTitle="Rejected List">
            <div className="col-sm-3">
              <TextBox 
              type="text" 
              placeholder="Search"
              value={search}
              onChange={(e) => searchDetails(e.target.value)}
              />
          </div>
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    {/* <th>KIC Id</th> */}
                    <th>KIC Reg No</th>
                    <th>Form No</th>
                    <th>Zone</th>
                    <th>Unit</th>
                    <th>Name</th>
                    <th>Civil Id</th>
                    <th>Mobile</th>
                    <th>Whatsapp</th>
                    <th>Unit Status</th>
                    <th>Zone Status</th>
                    <th>Central Status</th>
                    <th>Reason</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                {filtered.length > 0 ? filtered.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        {/* <td>{item.kic_id_no}</td> */}
                        <td>{item.kic_reg_no}</td>
                        <td>{item.form_no}</td>
                        <td>{item.zone_name}</td>
                        <td>{item.unit_name}</td>
                        <td>{item.name}</td>
                        <td>{item.civil_id_no}</td>
                        <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                        <td>{item.whatsapp}</td>
                        <td>{item.unit_status == 0 ? 'Pending' : item.unit_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>{item.zone_status == 0 ? 'Pending' : item.zone_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>{item.central_status == 0 ? 'Pending' : item.central_status == 1 ? 'Approved' : 'Rejected'}</td>
                        <td>{item.rejection_reason}</td>
                        <td className="action-td">
                          <Link to={"/more-details/" + item.reg_id} title="View More Details" className="btn btn-sm btn-outline-custcolor">
                          View
                          </Link> &nbsp;&nbsp;
                          <Link to={"/registration/edit-registration/" + item.reg_id} title="Edit" className="btn btn-sm btn-outline-custcolor">
                            Edit/Approval
                          </Link>
                        </td>
                      </tr>
                    );
                  }) : <tr><td colSpan={13} style={{ textAlign: 'center' }}><p><b>No Data Found</b></p></td></tr>}
                </tbody>
              </table>
            </div>
          </Card>


        </div>
      </Wrapper>
    </>
  )
}


const Wrapper = styled.div`
.action-td{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
.table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  @media screen and (max-width: 480px) {
    .cust-pad{
        margin-top: unset;
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;