import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { Card, CleanButton, TextBox } from "../../components/library";
import setHeader from '../../components/Functions/setHeader';
import axiosConfig from "../../services/config/axiosConfig";
import Cookies from "js-cookie";

function Changepwd() {

    const [pwd, setPwd] = useState("");
    const [cpwd, setCpwd] = useState("");
    const [perror, setPwderror] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";

    useEffect(() => {
        nCheck()
    }, [pwd])

    const clearPageState = () => {
      setPwd("");
      setCpwd("");
      setPwderror("");
  };

    const PwdCheck = (data) =>{
        setCpwd(data);
        if (data != "") {
            if(pwd !== data){
                setPwderror('Password not matching !');
            }else{
                setPwderror('');
            }
        } else {
          setPwderror('');
        }
    }

    const nCheck = () =>{
        if(cpwd !="" && pwd !== cpwd){
            setPwderror('Password not matching !');
        }else{
            setPwderror('');
        }
    }

    const updatePwd = () =>{

        const formValues = { cpwd, isRole, userID, zoneID }

        if(pwd !="" && cpwd !="" && perror ==""){
            axiosConfig.post("change-password", formValues, setHeader()).then((res) => {
              if (!res.data.error) {
              alert(res.data.messages);
              clearPageState();
              
              } else {
                  alert(res.data.messages);
              }
            }).catch((error) => {
            alert("something went wrong! try again");
            });
        }else{
            alert("Fill all the fields or Enter a matching password")
        }

    }

  return (
    <>
      <Wrapper className="container">
        <Card cardTitle="Change Password">
            <div className="row">
                <div className="col-sm-4">
                  <FormGroup>
                    <Label>New Password:</Label>
                    <TextBox 
                        type="password" 
                        name="pwd"
                        placeholder="Password"
                        value={pwd}
                        onChange={(e) => setPwd(e.target.value)}/>
                  </FormGroup>
                </div>
                <div className="col-sm-4">
                    <FormGroup>
                        <Label>Confirm Password:</Label>
                        <TextBox 
                        type="password" 
                        name="cpwd"
                        placeholder="Confirm Password"
                        value={cpwd}
                        onChange={(e) => PwdCheck(e.target.value)}/>
                        <p style={{ color: '#f00'}}>
                            {perror}
                        </p>
                    </FormGroup>
                </div>
                <div className="col-sm-2">
                    <Label></Label>
                    <CleanButton isPrimary onClick={updatePwd}  className="cust-pad">Submit</CleanButton>
                </div>
            </div>      
                
        </Card>
      </Wrapper>
    </>
  )
}

export default Changepwd

const Wrapper = styled.div`
    .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
    }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;