import Cookies from "js-cookie";
 const setHeader = () => {
  const userToken = Cookies.get("authToken");
  const token = userToken ? JSON.parse(userToken).token : "";
  return {
    headers: {
      Authorization: `${token}`,
    },
  };
};
export default setHeader;