import React from "react";
import styled from "styled-components";
export default function TextArea({
  name,
  id,
  placeholder,
  action,
  rows,
  column,
  value,
  ...rest
}) {
  return (
    <Wrapper
      id={id}
      name={name}
      column={column}
      rows={rows}
      placeholder={placeholder}
      onChange={action}
      defaultValue={value}
      {...rest}
    />
  );
}

const Wrapper = styled.textarea`
  display: block;
  width: 100%;
  height: auto;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  overflow: auto;
  resize: vertical;
  margin: 0;
  font-family: inherit;
  &:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: ${(p) => p.theme.colorPrimary};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
  }
  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }
  &:disabled,
  &:read-only {
    background-color: #e9ecef;
    opacity: 1;
  }
`;
