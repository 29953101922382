import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Card, CleanButton, TextBox, SelectBox } from "../../components/library";
import setHeader from '../../components/Functions/setHeader';
import axiosConfig from "../../services/config/axiosConfig";

function Unit() {

    const [units, setUnits] = useState([]);
    const [zone, setZone] = useState([]);
    const [zoneid, setZoneId] = useState("");
    const [unit, setUnit] = useState("");
    const [unitno, setUnitNo] = useState("");

    const [unitid, setUnitId] = useState("");
    const [search, searchDetails] = useState("");

    useEffect(() => {
        getData();
        ZoneData();
    }, []);

    const getData = () => {
        axiosConfig.get("units", setHeader()).then((res) => {
            setUnits(res.data.data);
        });
    };

    // filter table
    const filtered = !search
    ? units
    : units.filter((item) =>{
      return Object.keys(item).some(key=>
        item[key].toLowerCase().includes(search.toLowerCase())
        );
      });
      

    const ZoneData = () =>{
        axiosConfig.get("all-zones", setHeader()).then((res) => {
          setZone(res.data.data);
        }).catch((error) => {
          console.log(error);
        });
    };

    const clearPageState = () => {
        setZoneId("");
        setUnit("");
        setUnitNo("");
        setUnitId("");
        getData();
    };

    const saveUnit = () =>{
        const formValues = { zoneid, unit, unitno }

        if(zoneid!="" && unit !="" && unitno!=""){   
            axiosConfig.post("insert-unit", formValues, setHeader()).then((res) => {
                if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
                
                } else {
                    alert(res.data.messages);
                }
            }).catch((error) => {
            alert("something went wrong! try again");
            });
        
        } else {
            alert("Fill all the fields");
        }
    }

    const editUnit = (uid) =>{
        axiosConfig.get(`edit-unit/${uid}`, setHeader()).then((res) => {
            setUnitId(res.data.data[0].unit_id);
            setZoneId(res.data.data[0].zone_id);
            setUnit(res.data.data[0].unit_name);
            setUnitNo(res.data.data[0].unit_no);
        }).catch((error) => {
            alert("something went wrong! try again");
        });
    }

    const updateUnit = () =>{
        if(unitid!=""){
            const formData = { unitid, zoneid, unit, unitno };
            
            axiosConfig.post("update-unit", formData, setHeader()).then((res) => {
              if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
              } else {
                alert(res.data.messages);
              }
            }).catch((error) => {
              alert("something went wrong! try again");
            });
        } else {
            alert("Fill Unit");
        }
    }

    const DeleteUnit = (uid) =>{
        axiosConfig.get(`delete-unit/${uid}`, setHeader()).then((res) => {

            if (!res.data.error) {
                alert(res.data.messages);
                clearPageState();
            } else {
                alert(res.data.messages);
            }
        }).catch((error) => {
            alert("something went wrong! try again");
        });
    }

    

  return (
    <>
      <Wrapper className="container">
      <Card cardTitle="Units">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6">
                  <FormGroup>
                    <Label>Zone:</Label>
                    <SelectBox
                    data={zone}
                    name="zone"
                    value={zoneid}
                    placeholder="Select Zone"
                    onChange={(e) => setZoneId(e.target.value)}
                    />
                  </FormGroup>
                </div>
                <div className="col-sm-6">
                    <FormGroup>
                        <Label>Unit:</Label>
                        <TextBox 
                        type="text" 
                        name="unit"
                        placeholder="Unit"
                        value={unit}
                        onChange={(e) => setUnit(e.target.value)}/>
                    </FormGroup>
                </div>
              </div>
              <div className='row'>
                <div className="col-sm-6">
                    <FormGroup>
                        <Label>Unit Number:</Label>
                        <TextBox 
                        type="number" 
                        pattern="\d*"
                        name="unitno"
                        placeholder="Unit Number"
                        value={unitno}
                        onChange={(e) => setUnitNo(e.target.value)}/>
                    </FormGroup>
                </div>
                <div className="col-sm-6">
                <Label></Label>

                { unitid =="" ? 
                    ( <CleanButton isPrimary onClick={saveUnit}  className="cust-pad">Add New</CleanButton> ) : (
                    <CleanButton isPrimary onClick={updateUnit}  className="cust-pad">Update</CleanButton>
                    )
                } 
                </div>
              </div>
             
            </div>
            <div className="col-sm-6">
              <div className="col-sm-4">
                <TextBox 
                type="text" 
                placeholder="Search"
                value={search}
                onChange={(e) => searchDetails(e.target.value)}
                />
              </div>
              <div className="table-responsive mx-height">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Zone</th>
                      <th>Unit</th>
                      <th>Unit Number</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filtered.map((item,index) => {
                      return(
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.zone_name}</td>
                          <td>{item.unit_name}</td>
                          <td>{item.unit_no}</td>
                          <td>
                              <Link to={"/units"} onClick={() => editUnit(item.unit_id)} title="Edit" className="btn btn-sm btn-outline-custcolor">
                                Edit
                              </Link>&nbsp;
                              <Link to={"/units"} onClick={() => window.confirm("Are you sure ?") ? DeleteUnit(item.unit_id) : ""} title="Delete" className="btn btn-sm btn-outline-custcolor">
                               Delete
                              </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Card>
      </Wrapper>
    </>
  )
}

export default Unit

const Wrapper = styled.div`

   table {
    border-collapse: collapse;
    width: 100%;
    
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #aab1e3;
    text-align: left;
  }
  .table-responsive{
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .mx-height{
    height: 440px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
  }

`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;