import React, {useState, useEffect} from "react";
import { Card, CleanButton, SelectBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import Cookies from "js-cookie";

export default function RenewedMusanada() {

    const [musanada, setMusanada] = useState([]);
    const [allmusperiod, setAllmusperiod] = useState([]);
    const [maxmusyear, setMaxMusanada] = useState("");
    const [musyear, setMusanadaPeriod] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
  
    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);

    const musperiod = !musyear ? maxmusyear : musyear;
    formValues.append('musperiod', musperiod);

    useEffect(() => {
      // getData();
      AllMusanadaYear();
      MaxMusanadaYear();
    }, []);

    const AllMusanadaYear = () => {
      axiosConfig.get("all-musanada-year", setHeader()).then((res) => {
        setAllmusperiod(res.data.data);
      });
    };

    const MaxMusanadaYear = () => {
      axiosConfig.get("max-musanada-year", setHeader()).then((res) => {
        setMaxMusanada(res.data.data[0].msp_id);
      });
    };
    
    const getData = () => {
      axiosConfig.post("renewed-list", formValues, setHeader()).then((res) => {
        setMusanada(res.data.data);
      });
    };

    const ShowAll = () => {
      getData();
    };

    const RenewedList = () => {
      axiosConfig.post("m-renewed-list", formValues, setHeader()).then((res) => {
        setMusanada(res.data.data);
      });
    };

    const PendingList = () => {
      axiosConfig.post("m-pending-list", formValues, setHeader()).then((res) => {
        setMusanada(res.data.data);
      });
    };

    const ClosedList = () => {
      axiosConfig.post("m-closed-list", formValues, setHeader()).then((res) => {
        setMusanada(res.data.data);
      });
    };

  return (
    <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Musanada Renewed List">

        <div className="row">
          <div className="col-sm-2 col-md-2 col-lg-2">
              <FormGroup>
                <Label />
                <SelectBox
                    data={allmusperiod}
                    name="musperiod"
                    placeholder="Musanada Period"
                    onChange={(e) => setMusanadaPeriod(e.target.value)}
                  />
              </FormGroup>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isAccent className="cust-pad" onClick={ShowAll}>Show All</CleanButton>
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isPrimary className="cust-pad" onClick={RenewedList}>Renewed List</CleanButton>
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isDefault className="cust-pad" onClick={PendingList}>Pending List</CleanButton>
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-2 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isDefault className="cust-pad" onClick={ClosedList}>Closed List</CleanButton>
            </FormGroup>
          </div>
        </div>

        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>KIC Id</th>
              <th>KIC Reg No</th>
              <th>Zone</th>
              <th>Unit</th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              {/* <th>From</th>
              <th>To</th> */}
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
          {musanada.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.kic_id_no}</td>
                  <td>{item.kic_reg_no}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td>{item.current_no}</td>
                  {/* <td>{item.renewal_date}</td>
                  <td>{item.p_date}</td> */}
                  <td>{item.user_status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
      </Card>
    </div>
    </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  .table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;