import React, { useState } from "react";
import styled from "styled-components";
import { Link, NavLink, useLocation } from "react-router-dom";

export default function NavItems({ data, sideBar }) {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  let pathSplit = location.pathname.split("/");
  return data.sub ? (
    <ListWrapper onMouseLeave={() => setIsOpen(false)}>
      <DropDownWrapper
        onClick={() => setIsOpen(!isOpen)}
        className={data.base == pathSplit[1] && "active"}
        isSidebar={sideBar}
      >
        <LabelWrapper>
          <StyledIcon className={data.icon}></StyledIcon>
          <NavName>{data.name}</NavName>
        </LabelWrapper>
        <StyledIconDown
          className="uil uil-angle-down"
          active={isOpen}
        ></StyledIconDown>
      </DropDownWrapper>
      <NavSubLinkWrapper className={isOpen && "active"}>
        {data.sub.map((item, index) => {
          return (
            <li key={index}>
              <NavSubLink to={item.path} activeClassName="active" exact>
                {item.name}
              </NavSubLink>
            </li>
          );
        })}
      </NavSubLinkWrapper>
    </ListWrapper>
  ) : (
    <ListWrapper>
      <NavLinkWrapper
        to={data.path}
        activeClassName="active"
        exact
        isSidebar={sideBar}
      >
        <StyledIcon className={data.icon}></StyledIcon>
        <NavName>{data.name}</NavName>
      </NavLinkWrapper>
    </ListWrapper>
  );
}
const ListWrapper = styled.li`
  position: relative;
  display: block;
`;
const DropDownWrapper = styled.div`
  padding: ${(p) =>
    p.isSidebar ? "0.5rem 0 0.5rem 1.5rem" : "7px 0 7px 12px"};
  position: relative;
  margin-bottom: 1rem;
  transition: 0.3s;
  color: ${(p) => p.theme.colorNavbarLabel};
  font-size: ${(p) => p.theme.lenMd1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &.active {
    color: ${(p) => p.theme.colorPrimaryText};
    background-color: ${(p) => p.theme.colorPrimary};
    border-radius: 2rem;
  }
  &:hover {
    color: ${(p) => p.theme.colorNavbarLink};
  }
`;
const LabelWrapper = styled.div`
  align-items: center;
  display: flex;
`;
const NavSubLinkWrapper = styled.ul`
  /* margin-bottom: 0.8rem; */
  padding: 0;
  list-style: none;
  display: none;
  transition: 0.3s;
  &.active {
    display: block;
  }
`;
const NavSubLink = styled(NavLink)`
  padding: 0.5rem 0 0.5rem 3.7rem;
  position: relative;
  margin-bottom: 1rem;
  transition: 0.3s;
  color: ${(p) => p.theme.colorNavbarLabel};
  font-size: ${(p) => p.theme.lenMd1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &.active {
    color: ${(p) => p.theme.colorNavbarLink};
  }
  &:hover {
    color: ${(p) => p.theme.colorNavbarLink};
  }
`;

const NavLinkWrapper = styled(NavLink)`
  padding: ${(p) =>
    p.isSidebar ? "0.5rem 0 0.5rem 1.5rem" : "7px 0 7px 12px"};
  position: relative;
  margin-bottom: 1rem;
  transition: 0.3s;
  color: ${(p) => p.theme.colorNavbarLabel};
  font-size: ${(p) => p.theme.lenMd1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &.active {
    color: ${(p) => p.theme.colorPrimaryText};
    background-color: ${(p) => p.theme.colorPrimary};
    border-radius: 2rem;
  }
  &:hover {
    color: ${(p) => p.theme.colorNavbarLink};
  }
`;
const StyledIcon = styled.i`
  font-size: 1.25rem;
  margin-right: 1rem;
`;
const StyledIconDown = styled.i`
  font-size: 1rem;
  margin-right: 1.3rem;
  ${(p) => p.active && "transform: rotate(180deg)"};
`;
const NavName = styled.span``;
