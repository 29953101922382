import React, {useState, useEffect} from "react";
import { Card, Loading, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';
import Cookies from "js-cookie";

export default function FullRegistrations() {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [search, searchDetails] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
    const period = Cookies.get("activeperiod");
    const activepdate = Cookies.get("activepdate");

    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);
    formValues.append('period', period);

    useEffect(() => {
      getData();
    }, []);
    
    const getData = () => {
      axiosConfig.post("total-Registrations", formValues, setHeader()).then((res) => {
        setData(res.data.data);
        setIsLoading(false);
      });
    };

    
    // filter table
    const filtered = !search
    ? data
    : data.filter((item) =>{
      return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''

        );
      });
  return (
    <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Total Registration List">
        <div className="col-sm-3">
            <TextBox 
            type="text" 
            placeholder="Search"
            value={search}
            onChange={(e) => searchDetails(e.target.value)}
            />
        </div>
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              <th>Form No</th>
              <th>Zone <span>{activepdate}</span></th>
              <th>Unit <span>{activepdate}</span></th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {filtered.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  <td>{item.form_no}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  {/* <td>
                      <Link to={"/registration/edit-registration/" + item.reg_id} title="Edit" className="btn btn-sm btn-outline-custcolor">
                        Edit
                      </Link>
                  </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>
    
      </Card>
    </div>
    </Wrapper>
    {isLoading && <Loading />}
    </>
  )
}


const Wrapper = styled.div`
  .table-responsive {
    overflow-x: auto;
    span{
      font-size: 14px;
      display: inline-block;
      
    }
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;