import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import { useHistory } from "react-router";
import { useStateValue } from "../../contextAPI/GlobelState";
import { CleanButton } from "../../components/library";
import logo from "../../images/logo.png";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';


const Login = () => {
    const [{}, dispatch] = useStateValue();
    const history = useHistory();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pageData, setpageData] = useState({
      username: "",
      password: "",
      isLoading: false,
    });
    useEffect(() => {
      checkIsLogin();
      MusandaDateCheck();
      RenewalDateCheck();
    }, []);
    const checkIsLogin = () => {
      Cookies.get("authToken") && history.push("/");
    };

    const MusandaDateCheck = () => {
      axiosConfig.get("musanada-date-check", setHeader()).then((res) => {
        // console.log(res.data);
      });
    };

    const RenewalDateCheck = () => {
      axiosConfig.get("membership-renewal-date-check", setHeader()).then((res) => {
        // console.log(res.data);
      });
    };

    const ActivePeriod = () => {
      axiosConfig.get("active-period", setHeader()).then((res) => {
        Cookies.remove("activeperiod");
        Cookies.set("activeperiod", res.data.data[0].period_id);
      });
    };

    const loginAction = (e) => {
      e.preventDefault();
      if (pageData.username != "" || pageData.password != "") {
        const formData = {
          username: pageData.username,
          password: pageData.password,
        };
        axiosConfig.post("login", formData).then((res) => {
          if (!res.data.error) {
            const token = {
              token: res.data.data.token,
              role: res.data.data.role,
              id: res.data.data.id,
              zid: res.data.data.zid,
            };
            // console.log(token);
            Cookies.set("authToken", JSON.stringify(token));
            dispatch({ type: "SET_TOKEN", details: res.data.data.token });
            dispatch({ type: "LOGIN_AUTH", details: true });
            dispatch({ type: "SET_ROLE", details: res.data.data.role });
            ActivePeriod();
            
            // console.log('login',Cookies.get('activeperiod'));
            history.push("/");
            MusandaDateCheck();
          } else {
            alert("Invalid Username or Password !");
          }
        }).catch((error)=>{
          console.log(error);
        });
      } else {
        alert("Enter username and password");
      }
    };
  
    return (
      <Wrapper className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="my-form">
              <div className="logo-sec">
                <img src={logo} alt="" />
              </div>
              <div className="login-title">
                <h3>Kuwait Kerala Islamic Council</h3>
                {/* <span>Islamic Council</span> */}
              </div>
              <form onSubmit={loginAction}>
                <div className="single-input">
                  <span>
                    <i className="uil-user"></i>
                  </span>
                  <input
                    type="text"
                    placeholder="User Name"
                    name="username"
                    onChange={(e) =>
                      setpageData({ ...pageData, username: e.target.value })
                    }
                    value={pageData.username}
                  />
                </div>
                <div className="single-input">
                  <span>
                    <i className="uil-lock"></i>
                  </span>
                  <input
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    onChange={(e) =>
                      setpageData({ ...pageData, password: e.target.value })
                    }
                    value={pageData.password}
                  />
                  <span  onClick={() => setIsRevealPwd(prevState => !prevState)}>
                    {isRevealPwd ? <i className="uil-eye-slash"></i> : <i className="uil-eye"></i>}
                  </span>
                </div>
  
                <div className="submit-btn">
                  <CleanButton isPrimary>Login</CleanButton>
                </div>
                {/* <div className="forgot">
                  <Link to="">Forgot Password ?</Link>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </Wrapper>
    );
}

export default Login;

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgb(245 240 255) 0%,
    rgb(249 249 249) 100%
  );

  .logo-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 142px;
      @media (max-width: ${(p) => p.theme.screenWidthSm}) {
        height: 100px;
      }
    }
  }
  .my-form {
    background: #fff;
    width: 100%;
    padding: 30px 50px;
    border-radius: 10px;
    @media (max-width: ${(p) => p.theme.screenWidthSm}) {
      padding: 30px 20px;
    }
  }
  .login-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    h3 {
      font-size: 18px;
      margin: 12px 0;
      font-weight: 600;
      color: ${(p) => p.theme.colorPrimary};
    }
    span {
      font-size: 15px;
      margin: -8px 0px 7px 0px;
      font-weight: 600;
    }
  }
  .single-input {
    width: 100%;
    border: 1px solid #c1c1c1;
    border-radius: 10px;
    display: flex;
    margin-bottom: 15px;
    span i {
      padding: 8px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${(p) => p.theme.colorPrimary};
      @media (max-width: ${(p) => p.theme.screenWidthSm}) {
        padding: 11px 8px;
      }
    }
    input {
      border: 0px solid #c1c1c1;
      width: 100%;
      outline: none;
      height: 36px;
      font-size: 15px;
      ::placeholder {
        font-size: 14px;
        color: #c1c1c1;
        opacity: 1;
      }
      :-ms-input-placeholder {
        color: #c1c1c1;
      }
      ::-ms-input-placeholder {
        color: #c1c1c1;
      }
    }
    :focus-within {
      box-shadow: 0 0 0 0.2rem rgb(100 60 181 / 27%);
    }
  }
  .submit-btn {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .forgot {
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      margin-top: 10px;
    }
  }
`;