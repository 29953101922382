import React, {useState, useEffect} from "react";
import { Card, Loading, SelectBox, TextBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { Link } from "react-router-dom";
import setHeader from '../../components/Functions/setHeader';
import Cookies from "js-cookie";

export default function MusanadaList() {

    const [musanada, setMusanada] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allmusperiod, setAllmusperiod] = useState([]);
    const [maxmusyear, setMaxMusanada] = useState("");
    const [search, searchDetails] = useState("");

    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";
  
    const formValues = new FormData();
    formValues.append('id', userID);
    formValues.append('role', isRole);
    formValues.append('zid', zoneID);
    formValues.append('musperiod', maxmusyear);

    useEffect(() => {
      getData();
      MaxMusanadaYear();
      AllMusanadaYear();
    }, [maxmusyear]);
   
    const getData = (id) => {
      if (id) {
        formValues.append('musperiod', id);
      }
      axiosConfig.post("musanada-list", formValues, setHeader()).then((res) => {
        setMusanada(res.data.data);
        setIsLoading(false);
      });
    };
    const MaxMusanadaYear = () => {
      axiosConfig.get("max-musanada-year", setHeader()).then((res) => {
        setMaxMusanada(res.data.data[0].msp_id);
      });
    };

    const AllMusanadaYear = () => {
      axiosConfig.get("active-musanda-years", setHeader()).then((res) => {
        setAllmusperiod(res.data.data);
      });
    };

      // filter table
  const filtered = !search
  ? musanada
  : musanada.filter((item) =>{
    return Object.keys(item).some(key=>
        item[key] ? item[key].toLowerCase().includes(search.toLowerCase()) : ''
      );
    });


  return (
    <>
    <Wrapper>
    <div className="container">
        <div className="row">
            <div className="col-sm-2 col-md-4 col-lg-2">
              <FormGroup>
                <SelectBox
                    data={allmusperiod}
                    name="musperiod"
                    placeholder="Musanada Period"
                    onChange={(e) => getData(e.target.value)}
                  />
              </FormGroup>
            </div>
        </div>
      <Card cardTitle="Musanada Balance to Renew List">
      <div className="col-sm-3">
              <TextBox 
              type="text" 
              placeholder="Search"
              value={search}
              onChange={(e) => searchDetails(e.target.value)}
              />
          </div>
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              <th>Zone</th>
              <th>Unit</th>
              <th>Name</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              <th>Renewal</th>
            </tr>
          </thead>
          <tbody>
          {filtered.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  <td>{item.name}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  <td>
                      <Link to={"/musanada-renew/" + item.reg_id} title="Renew" className="btn btn-sm btn-outline-custcolor">
                        Renew
                      </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        </div>

      </Card>
    </div>
    </Wrapper>
    {isLoading && <Loading />}

    </>
  )
}


const Wrapper = styled.div`
  .table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
`;
const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
