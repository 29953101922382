import React, { useState, useEffect } from 'react';
import { Card, CleanButton, TextBox, SelectBox } from "../../components/library";
import styled from "styled-components";
import axiosConfig from "../../services/config/axiosConfig";
import { Link } from "react-router-dom";
import setHeader from '../../components/Functions/setHeader';
import { rgba } from "polished";
import Cookies from "js-cookie";

function RegSearch() {

    const [zone, setZone] = useState([]);
    const [period, setPeriod] = useState([]);
    const [unit, setUnit] = useState([]);
    const [alldata, setAlldata] = useState([]);

    const [zid, setUnitdata] = useState("");
    const [cunit, setCunit] = useState("");
    const [kicid, setKicid] = useState("");
    const [kicregno, setKicregno] = useState("");
    const [civilid, setCivilid] = useState("");
    const [mob, setMob] = useState("");
    const [name, setName] = useState("");
    const [housename, setHouseName] = useState("");
    const [periodid, setPeriods] = useState("");


    const isAuthorized = Cookies.get("authToken");
    const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
    const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
    const zoneID = isAuthorized ? JSON.parse(isAuthorized).zid : "";


    useEffect(() => {
      Allperiod();
        ZoneData();
      }, []);
      
    const Allperiod = () =>{
        axiosConfig.get("all-sel-periods", setHeader()).then((res) => {
          setPeriod(res.data.data);
        }).catch((error) => {
          console.log(error);
        });
    };

    const ZoneData = () =>{
        axiosConfig.get("all-zones", setHeader()).then((res) => {
          setZone(res.data.data);
        }).catch((error) => {
          console.log(error);
        });
    };

    const UnitData = (id) =>{
        axiosConfig.get(`all-units/${id}`, setHeader()).then((res) => {
          setUnitdata(id);
          setUnit(res.data.data);
        }).catch((error) => {
          console.log(error);
        });
    };

    const clearData = () => {
      setPeriods("");
      setUnitdata("");
      setCunit("");
      setKicid("");
      setKicregno("");
      setCivilid("");
      setMob("");
      setName("");
      setHouseName("");
      setAlldata([]);
    };

    const searchDetails = () => {
      if(periodid!=""){
          const formData = {
            zid,
            cunit,
            kicid,
            kicregno,
            civilid,
            mob,
            name,
            housename,
            isRole,
            userID,
            zoneID,
            periodid
          };
       
          // console.log(formData);
          axiosConfig.post("search-data", formData, setHeader()).then((res) => {
            setAlldata(res.data.data);
          });
        } else {
          alert("Fill Period");
        }
    
      };

  return (
    <>
    <Wrapper>
    <div className="container">
      <Card cardTitle="Search Details">
        
        <div className="row">
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label className="phpe">Period:</Label>
              <SelectBox
                  data={period}
                  name="period"
                  value={periodid}
                  placeholder="Select Period"
                  onChange={(e) => setPeriods(e.target.value)}
                />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label className="phpe">Zone:</Label>
              <SelectBox
                  data={zone}
                  name="zone"
                  value={zid}
                  placeholder="Select Zone"
                  onChange={(e) => UnitData(e.target.value)}
                />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label className="phpe">Unit:</Label>
              <SelectBox
                  data={unit}
                  name="unit"
                  value={cunit}
                  placeholder="Select Unit"
                  onChange={(e) => setCunit(e.target.value)}
                />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>KIC Id:</Label>
              <TextBox
                type="text"
                name="kicid"
                value={kicid}
                onChange={(e) => setKicid(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>KIC Reg No:</Label>
              <TextBox 
                type="text" 
                name="regno" 
                value={kicregno}
                onChange={(e) => setKicregno(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>Civil Id:</Label>
              <TextBox 
                type="text" 
                name="name" 
                value={civilid}
                onChange={(e) => setCivilid(e.target.value)}
              />
            </FormGroup>
          </div>
        </div>

        <div className="row">
          
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>Mobile:</Label>
              <TextBox 
                type="number" 
                name="mob" 
                value={mob}
                onChange={(e) => setMob(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>Name:</Label>
              <TextBox 
                type="text" 
                name="name" 
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-4 col-lg-2">
            <FormGroup>
              <Label>House Name:</Label>
              <TextBox 
                type="text" 
                name="housename" 
                value={housename}
                onChange={(e) => setHouseName(e.target.value)}
              />
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isPrimary className="cust-pad" onClick={searchDetails}>Search</CleanButton>
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
            <Label />
            <CleanButton isDefault className="cust-pad" onClick={clearData}>Clear</CleanButton>
            </FormGroup>
          </div>
          <div className="col-sm-2 col-md-3 col-lg-2">
            <FormGroup>
            <Label />
            <Link to={"/all-registration"} isAccent className="showbtn">Show All</Link>
            </FormGroup>
          </div>
          
        </div>
      </Card>

      <Card cardTitle="All Registrations">
        <div className="table-responsive">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Zone</th>
              <th>Unit</th>
              {/* <th>KIC Id</th> */}
              <th>KIC Reg No</th>
              <th>Civil Id</th>
              <th>Mobile</th>
              <th>Whatsapp</th>
              <th>Name</th>
              <th>House Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {alldata.length > 0 ? alldata.map((item,index) => {
              return(
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.zone_name}</td>
                  <td>{item.unit_name}</td>
                  {/* <td>{item.kic_id_no}</td> */}
                  <td>{item.kic_reg_no}</td>
                  <td>{item.civil_id_no}</td>
                  <td><a href={`tel:${item.mobile}`}>{item.mobile}</a></td>
                  <td>{item.whatsapp}</td>
                  <td>{item.name}</td>
                  <td>{item.house_name}</td>
                  <td className="action-td">
                      {/* <Link to={"/registration/edit-registration/" + item.reg_id} title="Edit" className="btn btn-sm btn-outline-custcolor">
                        Edit
                      </Link> */}
                      <Link to={"/more-details/" + item.reg_id} title="View More Details" className="btn btn-sm btn-outline-custcolor">
                        View
                      </Link> &nbsp;&nbsp;
                      {item.period_id > 3 ?
                        <Link to={"/membership-id/" + item.reg_id} title="ID Card" className="btn btn-sm btn-outline-custcolor">
                        ID
                        </Link> : ''
                      }
                    </td>
                </tr>
              );
            }): <tr><td colSpan={13} style={{ textAlign: 'center' }}><p><b>No Data Found</b></p></td></tr>}
          </tbody>
        </table>
        </div>
      </Card>


    </div>
    </Wrapper>
    </>
  )
}

export default RegSearch;

const Wrapper = styled.div`
.action-td{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
.table-responsive {
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    width: 100%;
  }
  table th,
  td {
    padding: 25px 10px 25px 10px;
    border-bottom: 1px solid #e4e4e4;
    text-align: left;
  }
  .cust-pad{
      padding: 11px 15px 11px 15px !important;
      margin-top: 8px;
  }
  .showbtn{
    background: ${(p) => p.theme.colorAccent};
    color: ${(p) => p.theme.colorAccentText};
    padding: 11px 15px 11px 15px !important;
    margin-top: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-width: 100px;
    border-radius: ${(p) => p.theme.borderRadius};

    transition-property: color, background, box-shadow;
    transition-duration: 0.35s;
    &:focus {
      box-shadow: 0 0 0 4px ${(p) => rgba(p.theme.colorAccent || "white", 0.4)};
    }
  }
  @media screen and (max-width: 480px) {
    .cust-pad{
        margin-top: unset;
    }
  }
`;

const Label = styled.label`
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;
const StyledIcon = styled.i`
  cursor: pointer;
  font-size: 16px;
  color: ${(p) => p.theme.colorPrimary};
`;