import React from 'react'
import Bootstrap from "../../components/styles/Bootstrap";
import { Route } from "react-router-dom";
import styled from "styled-components";

export default function PublicRoute({ component, ...rest }) {
  return (
    <StyledWrapper>
      <Bootstrap />
      <Route {...rest}>
          {React.createElement(component)}
      </Route>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (min-width: ${(p) => p.theme.screenWidthMd}) {
    flex-direction: row;
  }
`;