import React from "react";
import axios from "axios";
export const API_URL = "https://app.kuwaitskssf.com/api/api/";
// export const API_URL = "http://localhost:80/kic-api/api/";

// export const SITE_URL = "http://localhost:80/kic-api";
export const SITE_URL = "https://app.kuwaitskssf.com/api";


export const API = axios.create({ baseURL: API_URL });

//export const API_URL='http://starlex.regallivemedia.com/';

// const instance = axios.create({
//   baseURL: 'https://some-domain.com/api/',
//   timeout: 1000,
//   headers: {'X-Custom-Header': 'foobar'}
// });
