import React from "react";
import styled from "styled-components";
export default function SelectBox({
  name,
  id,
  action,
  data,
  placeholder,
  ...rest
}) {
  return (
    <Wrapper {...rest}>
      <option value="" selected style={{ display: "none" }}>
        {placeholder}
      </option>
      {data.map((item, i) => {
        return (
          <option value={item.value} key={i}>
            {item.label}
          </option>
        );
      })}
    </Wrapper>
  );
}
const Wrapper = styled.select`
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  display: block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-transform: none;
  margin: 0;
  font-family: inherit;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: ${(p) => p.theme.colorPrimary};
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(102, 110, 232, 0.25);
  }
`;
