import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import NavItems from "./NavItems";
import sidebarData from "../../assets/json/sidebarData";
import logo from "../../images/logo.png";
import Cookies from "js-cookie";
import { useStateValue } from "../../contextAPI/GlobelState";
import { useHistory } from "react-router";
import axiosConfig from "../../services/config/axiosConfig";
import setHeader from '../../components/Functions/setHeader';

function Navbar() {
  const [utype, setUsertype] = useState("");

  const toggleBtnReff = useRef(null);
  const navReff = useRef(null);
  const headerReff = useRef(null);
  const [isToggleBtn, setIsToggleBtn] = useState(false);
  const [isSideBar, setIsSideBar] = useState(false);

  const [{}, dispatch] = useStateValue();
  const history = useHistory();
  const isAuthorized = Cookies.get("authToken");
  const isRole = isAuthorized ? JSON.parse(isAuthorized).role : "";
  const userID = isAuthorized ? JSON.parse(isAuthorized).id : "";
  const currentSidebar = sidebarData.filter((item) => {
    return item.role == isRole;
  });

  const [activepdate, setPeriod] = useState("");

  const formValues = new FormData();
  formValues.append('id', userID);
  formValues.append('role', isRole);

  useEffect(() => {
    userTypes();
    ActivePeriod();
  }, []);

  const userTypes = () =>{
    axiosConfig.post("user-type", formValues, setHeader()).then((res) => {
      setUsertype(res.data.data[0].username);

    }).catch((error) => {
      console.log(error);
    });
  };

  const ActivePeriod = () => {
    axiosConfig.get("active-period", setHeader()).then((res) => {
      setPeriod(res.data.data[0].p_date);
    });
  };

  const logout = () => {
    Cookies.remove("authToken");
    dispatch({ type: "LOGIN_AUTH", details: false });
    dispatch({ type: "SET_ROLE", details: "" });
    history.push("/login");
  };

  const toggleSideBar = () => {
    setIsSideBar(!isSideBar);
    const bodypd = document.getElementById("body-pd");
    bodypd.classList.toggle("body-pd");
    navReff.current.classList.toggle("show");
    toggleBtnReff.current.classList.toggle("open");
    toggleBtnReff.current.children[0].classList.toggle("uil-multiply");
    //   .getElementsByTagName("i")
    //   .classList.toggle("uil-multiply");
    headerReff.current.classList.toggle("body-pd");
  };
  const navListRef = useRef(null);
  const sidebarToggler = () => {
    setIsToggleBtn(!isToggleBtn);
    toggleSideBar();
  };
  const toggleSideBarMouse = () => {
    !isToggleBtn && window.innerWidth > 768 && toggleSideBar();
  };
  return (
    <div>
      <HeaderWrapper ref={headerReff}>
        <HeaderToggle ref={toggleBtnReff} onClick={() => sidebarToggler()}>
          <StyledIcon className="uil uil-bars"></StyledIcon><span className="a-year">{activepdate}</span>
        </HeaderToggle>
        <HeaderProfile>
          {/* <img
            src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg"
            alt=""
          /> */}
        </HeaderProfile>
        <Usertype>{utype}</Usertype>
      </HeaderWrapper>
      <SideBarWrapper
        ref={navReff}
        onMouseEnter={toggleSideBarMouse}
        onMouseLeave={toggleSideBarMouse}
      >
        <NavbarWrapper>
          <InnerWrapper>
            <NavLogo to="/" isSidebar={isSideBar}>
              {/* <BrandLogo className="uil uil-bus"></BrandLogo> */}
              <BrandName>
                {isSideBar && <img src={logo} alt="" />}
              </BrandName>
            </NavLogo>
            <NavList ref={navListRef}>
              {currentSidebar.map((item, index) => {
                return <NavItems data={item} sideBar={isSideBar} key={index} />;
              })}
              <ListWrapper onClick={() => logout()}>
                  <StyledIcon className="uil-sign-out-alt"></StyledIcon>
                  <NavName>Logout</NavName>
              </ListWrapper>
              {/* <NavItems />
              <NavItems />
              <NavItems />
              <NavItems /> */}
            </NavList>
          </InnerWrapper>
        </NavbarWrapper>
      </SideBarWrapper>
    </div>
  );
}

export default Navbar;
// font-size: ${(p) => p.theme.lenMd1};
const HeaderWrapper = styled.header`
  width: 100%;
  height: 3rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  background-color: ${(p) => p.theme.colorHeadBar};

  z-index: 4;
  transition: 0.5s;
  &.body-pd {
    padding-left: calc(60px + 1.5rem);
  }
  @media screen and (min-width: ${(p) => p.theme.screenWidthSm}) {
    height: calc(3rem + 0rem);
    padding: 0 2rem 0 calc(60px + 2.5rem);
  }
`;
const Usertype = styled.p`
  text-transform: Capitalize;
  color: ${(p) => p.theme.colorPrimary};
`;
const HeaderToggle = styled.div`
  color: ${(p) => p.theme.colorPrimary};
  font-size: 1.5rem;
  cursor: pointer;
  &.open {
    padding-left: calc(60px + 93px);
  }
  .a-year{
    font-size: 18px;
  }
`;
const StyledIcon = styled.i`
  font-size: 1.25rem;
  margin-right: 1rem;
`;
const HeaderProfile = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 40px;
    object-fit: cover;
  }
  @media screen and (min-width: ${(p) => p.theme.screenWidthSm}) {
    width: 40px;
    height: 40px;
    img {
      width: 45px;
    }
  }
`;
const SideBarWrapper = styled.div`
  position: fixed;
  top: 0;
  left: -30%;
  width: 60px;
  height: 100vh;
  background-color: ${(p) => p.theme.colorNavbar};
  padding: 0.5rem 1rem 0;
  transition: 0.5s;
  z-index: 4;
  &.show {
    left: 0;
    width: calc(60px + 164px);
  }
  @media screen and (min-width: ${(p) => p.theme.screenWidthSm}) {
    left: 0;
    padding: 1rem 0.5rem 1rem 0.5rem;
    &.show {
      width: calc(60px + 164px);
    }
  }
`;
const NavbarWrapper = styled.nav`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 100px;
  ::-webkit-scrollbar {
      display: none;
      -ms-overflow-style: none;
      scrollbar-width: none;
      /* width: 2px;
      background-color:#fff; */
    }
    /* ::-webkit-scrollbar-track {
      background: transparent; 
    }
    ::-webkit-scrollbar-thumb {
      background: #1b4e9b ;
      border-radius: 25px;
    } */
`;
const InnerWrapper = styled.div``;
const BrandLogo = styled.i`
  font-size: 1.25rem;
  /* color: #f7f6fb; */
`;
const BrandName = styled.span`
  /* color: #f7f6fb; */
  font-weight: 700;
  img {
    width: 150px;
    height: 100px;
  }
`;
const NavLogo = styled(Link)`
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  padding: ${(p) =>
    p.isSidebar ? "0.5rem 0 0.5rem 1.5rem" : "0.5rem 0 0.5rem 0.5rem"};
  // margin-bottom: 2rem;
  color: ${(p) => p.theme.colorPrimary};
`;
const NavList = styled.ul`
  padding: 0;
  list-style: none;
`;
const ListWrapper = styled.li`
  padding: ${(p) =>
    p.isSidebar ? "0.5rem 0 0.5rem 1.5rem" : "7px 0 7px 12px"};
  position: relative;
  margin-bottom: 1rem;
  transition: 0.3s;
  color: ${(p) => p.theme.colorNavbarLabel};
  font-size: ${(p) => p.theme.lenMd1};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  &.active {
    color: ${(p) => p.theme.colorPrimaryText};
    background-color: ${(p) => p.theme.colorPrimary};
    border-radius: 2rem;
  }
  &:hover {
    color: ${(p) => p.theme.colorNavbarLink};
  }
`;
const NavName = styled.span``;