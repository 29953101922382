import React from "react";
import styled, { keyframes } from "styled-components";
import { rgba } from "polished";
export default function Loading() {
  return (
    <Wrapper>
      <Prespinner />
    </Wrapper>
  );
}
const spin = keyframes`
  100% {transform: rotate(360deg) scale(1);}
  `;
const Wrapper = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${rgba("black", 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: visible;
  opacity: 1;
  transition-property: visibility, opacity;
  transition-duration: 0.5s;
  overflow-y: scroll;
  overflow-x: hidden;
`;
const Prespinner = styled.div`
  border-width: 10px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.15);
  border-top-color: #fff;
  width: 72px;
  min-width: 72px;
  height: 72px;
  min-height: 72px;
  border-radius: 50%;
  animation: ${spin} 1.5s infinite linear;
`;
